/* eslint-disable */
export function mapStyleGame(game: string) {
    switch (game) {
        case 'Tresette':
            return {
                background: '#D53900',
                backgroundGradient: 'linear-gradient(180deg, #F98850 0%, #E95A14 51.67%, #983A0C 100%)',
                color: '#fff',
            };
        case 'Solitario':
            return {
                background: '#0055BD',
                backgroundGradient: '#0055BD',
                color: '#fff',
            };
        case 'SetteEMezzo':
            return {
                background: '#86EB25',
                backgroundGradient: '#86EB25',
                color: '#fff',
            };
        case 'Scopone':
            return {
                background: '#14A2EA',
                backgroundGradient: '#14A2EA',
                color: '#fff',
            };
        case 'Scopa':
            return {
                background: '#E90012',
                backgroundGradient: '#E90012',
                color: '#fff',
            };
        case 'Scala40':
            return {
                background: '#00D1EB',
                backgroundGradient: '#00D1EB',
                color: '#fff',
            };
        case 'ItalianPoker':
            return {
                background: '#0042EE',
                backgroundGradient: '#0042EE',
                color: '#fff',
            };
        case 'Peppa':
            return {
                background: '#5300EC',
                backgroundGradient: '#5300EC',
                color: '#fff',
            };
        case 'Cirulla':
            return {
                background: '#00D7CD',
                backgroundGradient: '#00D7CD',
                color: '#fff',
            };
        case 'Burraco':
            return {
                background: '#E8DE00',
                backgroundGradient: '#E8DE00',
                color: '#fff',
            };
        case 'Briscola':
            return {
                background: '#0FC000',
                backgroundGradient: '#0FC000',
                color: '#fff',
            };
        case 'Bestia':
            return {
                background: '#00E929',
                backgroundGradient: '#00E929',
                color: '#fff',
            };
        case 'AssoPigliaTutto':
            return {
                background: '#C2EB14',
                backgroundGradient: '#C2EB14',
                color: '#fff',
            };
        case 'Telesina':
            return {
                background: '#E95A14',
                backgroundGradient: '#E95A14',
                color: '#fff',
            };
        case 'Trentuno':
            return {
                background: '#dcf151',
                backgroundGradient: '#dcf151',
                color: '#fff',
            };
        default:
                return {
                    background: '',
                    backgroundGradient: '',
                    color: '#fff',
                }
    }
}