import { Avatar, Box, styled } from "@mui/material"
import { useTranslation } from "react-i18next";
import { FlexBox } from "shared/Flex";

type UserItemProps = {
    moveData?: string;
    user?: any,
    isMine: boolean,
    diffrentPlayer?: boolean
}

const UserItemBox = styled(FlexBox)(() => ({
    justifyContent: 'flex-start',
    gap: '5px',
    marginTop: '10px',
    '&.mine': {
        flexDirection: 'row-reverse'
    }
}))

const ChatContent = styled(Box)(() => ({
    padding: '5px 15px',
    borderRadius: '0 10px 10px 10px',
    '.mine &': {
        borderRadius: '10px 0 10px 10px',
    }
}))

const UserName = styled('p')(() => ({
    fontSize: '14px',
    fontWeight: 500,
    color: '#E20613',
}))

const Content = styled(FlexBox)(() => ({
    alignItems: 'flex-end',
    gap: '10px',
}))

const Chat = styled('p')(() => ({
    wordBreak: `break-word`,
    fontSize: '16px',
    fontWeight: 500,
    flex: 1
}))

const Time = styled('p')(() => ({
    color: '#8E8E8E',
    fontSize: '8px',
    marginBottom: '2px',
}))

const UserItem = ({ user, isMine, diffrentPlayer, moveData }: UserItemProps) => {
    const { t } = useTranslation()
    const isSystem = user?.isSystem || false;
    const getFullHour = (date) => {
        const currentDate = new Date(date)
        const hour = String(currentDate.getHours()).padStart(2, '0');
        const minute = String(currentDate.getMinutes()).padStart(2, '0');
        const formattedHour = `${hour}:${minute}`;
        return formattedHour
    }

    
    return (
        <UserItemBox className={`user-item ${isMine ? 'mine' : ''}`}>
            {diffrentPlayer && (
                <Avatar alt={user?.playerName || ""} src={`/images/avatar/${user?.avatar}`} style={{ width: 30, height: 30 }} />
            )}
            <ChatContent className={`
                ${!diffrentPlayer && !isMine ? 'ml-[35px]' : ''}
                ${!diffrentPlayer && isMine ? 'mr-[35px]' : ''}
                user-chat-content
            `}>
                <UserName>{isSystem ? t('System') : (user?.playerName || "")}</UserName>
                <Content>
                    <Chat>{moveData ? moveData : (user?.message || "")}</Chat>
                    <Time>{getFullHour(user?.createdDate)}</Time>
                </Content>
            </ChatContent>
        </UserItemBox>
    )
}
export default UserItem