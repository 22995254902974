import clsx from 'clsx';
import {
  Game,
  GameHelper,
  GameRound,
  IAlertMessage,
  SessionInfo,
  cashTableActions,
  darkMode,
  footerMobileLeft,
  gameActions,
  isPopup,
  selectActiveGame,
  selectActiveMatchId,
  selectAlertMessage,
  selectCashTableRound,
  selectCasino,
  selectOrientation,
  selectRound,
  selectRounds,
  selectSessionInfo,
  useCheckLobbyPage,
} from '@quanticogames/gameclient-core';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { CONTAINER_ID } from '../../constants/common';
import { PATH_ROUTES } from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ModalSetUserName from 'shared/Tournament/ModalSetUserName';
import ModalLogGame from 'shared/components/modal-log-game';
import { EndSession, ConfirmEndSession } from 'shared';
import ChatDesktop from 'shared/Chat/Component/chat-desktop';
import ModalComponent from 'shared/components/modal';
import './index.scss';
import { ControlPanel } from 'layouts/control-panel';
import { Footer } from 'layouts/footer';
import { FooterMobile } from 'layouts/footer/component/footer-mobile';
import Header from 'layouts/headers';
import ToastContainerCustom from 'layouts/main-layout/component/toast-container';
import 'react-toastify/dist/ReactToastify.css';
import MatchSwitcher from 'shared/components/match-switcher';
import SliderTable from 'shared/components/slider-table';
import AlertMessageModal from 'shared/AlertMessageModal/AlertMessageModal';
import { useDispatch } from 'react-redux';
import InactiveNotice from 'layouts/inactive-notice';
import useSound from 'hooks/useSoundManage';

type roundStart = {
  gameCode: string;
  id: number;
};

const MainLayout = () => {
  const { t } = useTranslation();
  const isMobile = GameHelper.isMobile();
  const location = useLocation();
  const dispatch = useDispatch();
  const isLobby = useCheckLobbyPage();
  const isGamePage = location.pathname.includes(PATH_ROUTES.GAME);
  const [isRegulationPage, setIsRegulationPage] = useState<boolean>(false);
  const activeGame: Game = useSelector(selectActiveGame);
  const rounds: GameRound[] = useSelector(selectRounds);
  const url = new URL(window.location.href);
  const [openBlockPopup, setOpenBlockPopup] = useState<boolean>(false);
  const [roundStart, setRoundStart] = useState<roundStart>();
  const sessionInfo = useSelector(selectSessionInfo) as SessionInfo;
  const skinType: string = (useSelector(selectCasino) as string);
  const casino = (useSelector(selectCasino) as string) || 'quantico';
  const isDarkMode: boolean = useSelector(darkMode);
  const isFooterMenuLeft: boolean = useSelector(footerMobileLeft);
  const alertMessage: IAlertMessage = useSelector(selectAlertMessage);
  const [headerSmall, setHeaderSmall] = useState<boolean>(false);
  const isMobileLandscape = useSelector(selectOrientation) === 'landscape';
  const isMobilePortrait = useSelector(selectOrientation) === 'portrait';
  const isIpad = GameHelper.isTablet()
  const [showLayoutFooterMenuLeft, setShowLayoutMenuLeft] = useState<boolean>(false);
  const [isOpenAlertMessageModal, setIsOpenAlertMessageModal] = useState(false);
  const isCashTable = window.location.href.includes(PATH_ROUTES.TABLE);
  
  const round: any = useSelector(isCashTable ? selectCashTableRound() : selectRound());
  const [loading, setLoading] = useState<boolean>(true);


  useSound();

  useEffect(() => {
    if (alertMessage && !isOpenAlertMessageModal) setIsOpenAlertMessageModal(!isOpenAlertMessageModal);
  }, [alertMessage]);

  useEffect(() => {
    if (isFooterMenuLeft) {
      setShowLayoutMenuLeft(true);
    } else {
      setShowLayoutMenuLeft(false);
    }
  }, [isMobileLandscape, isFooterMenuLeft])

  useEffect(() => {
    if (location.pathname.includes('regulation')) {
      setIsRegulationPage(true);
    } else {
      setIsRegulationPage(false);
    }
  }, [location]);

  useEffect(() => {
    if (localStorage['allowNotification'] === '0') {
      return;
    }
    if (isPopup || isMobile) {
      return;
    }
  }, []);

  useEffect(() => {
    const listRoundStart = rounds.filter((item) => item.state === 'started');
    let roundStartLastId;
    let roundStartLast;
    if (listRoundStart.length > 0) {
      roundStartLast = listRoundStart[listRoundStart.length - 1];
      roundStartLastId = roundStartLast?.matchId;
      setRoundStart(roundStartLast);
    } else {
      setOpenBlockPopup(false);
    }

    if (isPopup === undefined && roundStartLastId) {
      setOpenBlockPopup(true);
    }
  }, [rounds]);

  const handleClickGioca = () => {
    if (!roundStart) return;
    const inGameUrl = `${url.origin}/game/${roundStart?.gameCode}?id=${roundStart?.id}`;
    window.open(inGameUrl, roundStart?.id.toString(), 'width=' + screen.availWidth + ',height=' + screen.availHeight);
    setOpenBlockPopup(false);
  };


  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  };

  const ticking = useRef(false);

  useEffect(() => {
    if (isMobileLandscape && !isIpad) {
      if (!skinType) return;
      const isColpoGrosso = location.pathname.includes('colpo-grosso') || location.pathname.includes('filotto');

      if (isColpoGrosso) {
        const containerLobby = document.querySelector('.container-lobby-page') as HTMLElement;
        if (containerLobby) {
          containerLobby.style.height = '330px';
        }
      }
      else { 
        const containerLobby = document.querySelector('.container-lobby-page') as HTMLElement;
        if (containerLobby) {
          containerLobby.style.height = '100%';
        }
      }
      const handleScroll = debounce(() => {
       
        if (!ticking.current) {
          window.requestAnimationFrame(() => {
            const isSmall = window.scrollY > 100;
            if (isSmall !== headerSmall) {
              setHeaderSmall(isSmall);
            }
            ticking.current = false;
          });
          ticking.current = true;
        }
      }, 50);

      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
    if(isMobilePortrait) {
      const containerLobby = document.querySelector('.container-lobby-page') as HTMLElement;
      if (containerLobby) {
        containerLobby.style.height = '100%';
      }
    }
  }, [isMobileLandscape, headerSmall, skinType]);

  let containerClass;

  switch (true) {
    case isLobby:
      containerClass = 'container-lobby';
      break;
    case isRegulationPage:
      containerClass = 'container-regulation';
      break;
    default:
      containerClass = `container-game container-game-${activeGame?.code}`;
      break;
  }

  useEffect(() => {
    if (sessionInfo) {
      dispatch(cashTableActions.loadOnGoingGame());
    }
  }, [sessionInfo]);

  useEffect(() => {
    if (sessionInfo) {
      const matchId = url.searchParams.get('id');
      const metaData = {
        isLobby: isLobby,
        matchId: isLobby ? null : matchId
      }
      dispatch(gameActions.ping(metaData))
    }
  }, [isLobby, sessionInfo]);


  useEffect(() => {

    if(sessionInfo) {
      dispatch(cashTableActions.loadOnGoingGame())
    }

  }, [sessionInfo]);
  const className = useMemo(() => {
    return clsx(
      'root-container',
      isMobile && 'mobile',
      !isMobile && 'desktop',
      isDarkMode ? 'dark-mode' : casino,
      isMobileLandscape && 'mobile-landscape',
      isMobileLandscape && (showLayoutFooterMenuLeft ? 'root-container-left' : 'root-container-right'),
      containerClass
    );
  }, [isMobile, isDarkMode, isMobileLandscape, showLayoutFooterMenuLeft, containerClass, casino]);

  useEffect(() => {
    if (round?.loaded) setLoading(false)
  }, [round?.loaded]);
  


  return (
    <>
      {skinType && <div id={CONTAINER_ID} className={className}>
        {sessionInfo?.isAuthenticated && !sessionInfo?.user?.userName && <ModalSetUserName />}

        <div className={`header-box ${isMobileLandscape ? headerSmall ? 'header-small' : '' : ''}`}>
          {((isMobile && !isGamePage) || !isMobile) && <Header />}

          {isLobby && !isRegulationPage && (
            <>
              <SliderTable />
              <ControlPanel />
            </>
          )}
        </div>

        <div className="main-content">
          <AlertMessageModal
            alertMessage={alertMessage}
            open={isOpenAlertMessageModal}
            onClose={() => setIsOpenAlertMessageModal(!isOpenAlertMessageModal)}
          />

          <div className="main-container">
            <Outlet />
          </div>
        </div>

        {(isLobby || isRegulationPage) && <Footer />}

        {isMobile && !isGamePage && <FooterMobile />}

        {loading && isGamePage ? <></> : <><MatchSwitcher /> <ChatDesktop /></>}

        {openBlockPopup && roundStart && (
          <ModalComponent
            title={t("Notification")}
            onOk={handleClickGioca}
            textOk={t("Play")}
            onCancel={() => setOpenBlockPopup(false)}
            className="modal-tooltip"
            open={openBlockPopup}
            size='small'
          >
            <p>{t('New game in tour started', { id: roundStart?.id })}</p>
          </ModalComponent>
        )}

        <ModalLogGame />
        <EndSession />
        <ConfirmEndSession />
        <ToastContainerCustom />
        <InactiveNotice />
      </div>}
    </>

  );
};
export default MainLayout;
