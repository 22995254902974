/* eslint-disable */
import {
  CancelTournamentReply,
  Game,
  ParticipantStatusEnum,
  Sound,
  SoundManager,
  Tournament,
  TournamentStateEnum,
  User,
  convertCentToEuro,
  matchActions,
  selectActiveGame,
  selectBalanceInfo,
  selectCancelReplyTournament,
  selectCurrentUser,
  selectOrientation,
  selectPassFirstRound,
  tournamentActions,
  tournamentApi,
} from '@quanticogames/gameclient-core';
import LockIcon from 'assets/icons/Lock';
import StarIcon from 'assets/icons/StarIcon';
import StarIconActive from 'assets/icons/StarIconActive';
import { chipGames } from 'constants/common';

import moment from 'moment';
import { TournamentBadge, TournamentCost, TournamentFavourite, TournamentLine, TournamentPlayer, TournamentPrize, TournamentStart, TournamentStatus } from 'pages/tournament/component/list/tournament-cell-mobile';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FlexBox } from 'shared/Flex';
import ButtonComponent from 'shared/components/button';
import { numberWithDecimals } from 'utils/formatCurrency';
import { getGameType, getSpeedLabel, getWinObject } from 'utils/tournamentTitle';

type TournamentItemMobileProps = {
  index: number;
  data: Tournament | any;
  onSelect: (id: number, index?: number) => void;
  onAction: (action: string, id: number, index?: number) => void;
  className?: string;
  isFake?: boolean;
};

const TournamentItemMobile = ({ index, data, onSelect, onAction, className, isFake }: TournamentItemMobileProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser: User = useSelector(selectCurrentUser);
  const location = useLocation();
  const path = location.pathname.split('/');
  const id = Number(path[path.length - 1]) || data?.id;
  const game: Game = useSelector(selectActiveGame);
  const balance = useSelector(selectBalanceInfo);
  const cancelTournamentData = useSelector(selectCancelReplyTournament(data?.id)) as CancelTournamentReply;
  const [isCancelTournament, setIsCancelTournament] = useState<boolean>(false);
  const tournamentPassRound = useSelector(selectPassFirstRound);
  const [showPassModal, setShowPassModal] = useState<boolean>(false);
  const [contentDialog, setContentDialog] = useState<string>('');
  const isMobileLandscape = useSelector(selectOrientation) === 'landscape';

  const [favoriteList, setFavoriteList] = useState<number[]>(() => {
    const storedFavorites = localStorage.getItem('favoriteList');
    return storedFavorites ? JSON.parse(storedFavorites) : [];
  });

  useEffect(() => {
    const storedFavorites = localStorage.getItem('favoriteList');
    if (storedFavorites) {
      setFavoriteList(JSON.parse(storedFavorites));
    }
  }, []);

  const handleActionFavourite = (id: number, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    const isFavorited = favoriteList.includes(id);
    if (isFavorited) {
      const updatedFavorites = favoriteList.filter((itemId) => itemId !== id);
    setFavoriteList(updatedFavorites);
    localStorage.setItem('favoriteList', JSON.stringify(updatedFavorites));
    } else {
      const updatedFavorites = [...favoriteList, id];
      setFavoriteList(updatedFavorites);
      localStorage.setItem('favoriteList', JSON.stringify(updatedFavorites));
    }
  };

  const renderFavoriteIcon = useMemo(
    () => (id: number) => {
      const isFavorited = favoriteList.includes(id);
      return isFavorited ? <StarIconActive /> : <StarIcon />;
    },
    [favoriteList]
  );


  useEffect(() => {
    if (cancelTournamentData) {
      setIsCancelTournament(true);
    }
  }, [cancelTournamentData]);

  const renderTitle = () => {
    const speed = data?.gameId == 9 && data?.maxPlayers == 2 ? 'Nibble' : getSpeedLabel(data?.speed);
  
    const isChipGames = (gameCode: string) => chipGames.includes(gameCode);
    const winObj = isFake ? "DEMO" : getWinObject(data?.objective, data?.objectiveValue, t);


    const currentTime = moment.utc();
    const startingTime = moment.utc(data.startingTime);
    const duration = startingTime.diff(currentTime, 'seconds');

    const showSeatCount = () => {
      if (isChipGames(game?.code)) {
        return `- ${data?.maxSeats} ${t('Positions')}`;
      } else return '';
    }

    
    if (data?.freeroll) {
      return `${t('Freeroll')} ${game?.name} ${t(speed)} ${winObj} ${getGameType(data?.type, t) ? `- ${getGameType(data?.type, t)}` : ''} ${data.startingTime && duration > 0 ? `${numberWithDecimals(convertCentToEuro(data?.rewardValue), 2)} EURO BONUS ${showSeatCount()}` : ''}`;
    }
    if (data?.guaranteedValue) {
      return `${game?.name} ${t(speed)} ${winObj} GTD ${numberWithDecimals(convertCentToEuro(data?.guaranteedValue), 2)} ${showSeatCount()}`;
    }
    return `${game?.name} ${t(speed)} ${winObj} ${getGameType(data?.type, t) ? `- ${getGameType(data?.type, t)}` : ''} ${data.startingTime && duration > 0 ? `${showSeatCount()}`: ''}`;
  };

  const tournamentHasPassword = () => {
    const tournamentPassword = data?.hasPassword == true;
    return tournamentPassword && <span style={{ marginBottom: '3px' }}><LockIcon stroke="white" /></span>;
  };

  const handleTournamentsDetail = () => {
    SoundManager.play(Sound.clickButton);
    dispatch(tournamentActions.tournamentsDetail(id));
    onSelect(data.id, index);
  };

  const handleOpenNewTab = (e: any, id: number) => {
    e.stopPropagation();
    const fetchData = async () => {
      try {
        const response = await tournamentApi.getDetails(id);
        const matchId = response.matches.find((m) => !!m.players.find((p) => p.id === currentUser.id));
        const spectatorMatchId = response.matches[0]?.id;
        if (matchId || spectatorMatchId) {
          dispatch(
            matchActions.openMatchWindow({
              id: matchId ? matchId?.id : spectatorMatchId,
              gameCode: game.code,
            })
          );
        }
      } catch (error) {
        console.error(error);
      }
    };

    const isDevelopment = /true/i.test(process.env.REACT_APP_DEVELOPMENT);

    if (isDevelopment) {
      onAction('play', id);
    } else {
      fetchData();
    }
  };

  const participantsLength = data?.participants?.length;
  const maxPlayers = data?.maxPlayers;
  const registeredNumber = data?.registered;
  let nonClickable =
    data?.state === TournamentStateEnum.scheduled ||
    data?.state === TournamentStateEnum.closed ||
    data?.state === TournamentStateEnum.terminated;
  nonClickable = nonClickable || data?.currentPlayerStatus == ParticipantStatusEnum.Eliminated
    || data?.participants?.some(item => item.userId == currentUser.id && item.status == ParticipantStatusEnum.Eliminated);

  const isSubscribed = data?.isSubscribed;
  const canPlay = data?.state === TournamentStateEnum.inprogress && isSubscribed;
  const canSubscribe = !isSubscribed && data.state != TournamentStateEnum.inprogress;
  const canUnsubscribe =
    isSubscribed && data.state != TournamentStateEnum.closed && data.state != TournamentStateEnum.inprogress;


  const currentTime = moment.utc();
  const startingTime = moment.utc(data.startingTime);
  const duration = startingTime.diff(currentTime, 'seconds');

  useEffect(() => {
    if (data.type === 'Scheduled' && tournamentPassRound) {
      setContentDialog('Sei passato al turno successivo, aspetta il tuo avversario');
      setShowPassModal(true);
    }
  }, [tournamentPassRound]);

  const handlePlayScheduled = (id: number) => {
    const fetchData = async () => {
      try {
        const response = await tournamentApi.getPlayerStatus(id);
        if (response) {
          if (response?.status === 2) {
            setContentDialog('Sei stato eliminato da questo torneo');
            setShowPassModal(true);
            return;
          }
          if (response?.status === 1 && response?.ongoingMatches?.length === 0) {
            setContentDialog('Sei passato al turno successivo, aspetta il tuo avversario');
            setShowPassModal(true);
            return;
          }
        }
        onAction('play', id);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  };


  const TournamentButton = () => {
    return (
      <>
        {canPlay && (
          <div className="flex items-center">
            <ButtonComponent
              variant="outlined"
              title={t('Play')}
              disabled={nonClickable}
              rouned="large"
              size="large"
              color="primary"
              fullWidth
              className="lobby-button"
              onClick={(e) => {
                if (data.type === 'Scheduled') {
                  e.stopPropagation();
                  handlePlayScheduled(data.id);
                } else {
                  e.stopPropagation();
                  onAction('play', id);
                }
              }}
              startIcon={data?.hasPassword && <span style={{ position: 'absolute', left: '11px', bottom: '13px' }}><LockIcon /></span>}
            />
          </div>
        )}

        {canSubscribe && (
          <ButtonComponent
            variant="contained"
            title={t('Subscribe')}
            rouned="large"
            size="large"
            color="success"
            fullWidth
            className="lobby-button subscribe-button"
            disabled={
              nonClickable ||
              balance === undefined ||
              participantsLength == maxPlayers ||
              maxPlayers === registeredNumber
            }
            onClick={(e) => {
              e.stopPropagation();
              onAction('subscribe', data);
            }}
            startIcon={data?.hasPassword && <span style={{ position: 'absolute', left: '9px', bottom: '14px' }}><LockIcon fill='white' /></span>}
          />
        )}

        {canUnsubscribe && (
          <ButtonComponent
            variant="outlined"
            title={t('Unsubscribe')}
            className="lobby-button"
            disabled={nonClickable}
            color="error"
            rouned="large"
            size="large"
            fullWidth
            onClick={(e) => {
              e.stopPropagation();
              onAction('unsubscribe', data.id);
            }}
          />
        )}

        {/* {!isSubscribed && data.state == TournamentStateEnum.inprogress && (
          <ButtonComponent
            variant="outlined"
            title={t('Spectator')}
            className="lobby-button"
            disabled={nonClickable}
            color="error"
            rouned="large"
            size="large"
            fullWidth
            onClick={(e) => handleOpenNewTab(e, data.id)}
            endIcon={isMobileLandscape && tournamentHasPassword() ? <>{tournamentHasPassword()}</> : null}
          />
        )} */}
      </>
    );
  };

  const TournamentDes = () => <p className="tournament-des-title">{renderTitle()}</p>;

  return (
    <FlexBox
      alignItems={isMobileLandscape ? 'flex-start' : 'center'}
      className={`tournament-mobile-item type-${data.type} ${className}`}
      onClick={handleTournamentsDetail}
    >
      <TournamentFavourite data={data} handleActionFavourite={handleActionFavourite} renderFavoriteIcon={renderFavoriteIcon} />

      <FlexBox
        gap={isMobileLandscape ? 15 : 6}
        flexDirection={isMobileLandscape ? 'row' : 'column'}
        alignItems={isMobileLandscape ? 'center' : 'flex-start'}
        width="100%"
        className={`tournament-mobile-content flex-1 ${isMobileLandscape ? 'tournament-mobile-content-landscape' : ''}`}
      >
        {isMobileLandscape ? (
          <>
            <FlexBox flexDirection="column" className="flex-1" gap={3}>
              <TournamentDes  />
              <FlexBox gap={10} alignItems="center" className="tournament-mobile-line">
                <TournamentBadge data={data} />
                <TournamentPlayer data={data} />
                <TournamentStart data={data} duration={duration} t={t} />
              </FlexBox>
            </FlexBox>

            <FlexBox flexDirection="column" width="120px" className="tournament-mobile-line">
              <TournamentCost data={data} t={t}  />
              <TournamentPrize data={data} t={t} />
            </FlexBox>

            <FlexBox flexDirection="column" width="85px" alignItems="center">
              <TournamentStatus data={data} t={t} />
            </FlexBox>

            <FlexBox flexDirection="column" width="120px">
              <TournamentButton />
            </FlexBox>
          </>
        ) : (
          <>
            <TournamentLine>
              <div className="tournament-des">
                <div className="tournament-des-content">
                  <TournamentDes  />
                  <TournamentStart data={data} duration={duration} t={t} />
                </div>
              </div>
              <TournamentButton />
            </TournamentLine>

            <TournamentLine>
              <TournamentCost data={data} t={t} />
              <TournamentStatus data={data} t={t} />
              <TournamentPlayer data={data} />
            </TournamentLine>

            <TournamentLine>
              <TournamentPrize data={data} t={t} />
              <TournamentBadge data={data} />
            </TournamentLine>
          </>
        )}
      </FlexBox>
    </FlexBox>
  );
};
export default TournamentItemMobile;
