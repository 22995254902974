import { BottomNavigation } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { SitGoMenuIconSvg } from '../../../assets/icons/SitGoMenuIconSvg';
import { PATH_ROUTES } from '../../../constants/routes';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Game,
  Sound,
  SoundManager,
  TournamentFilter,
  TournamentStateEnum,
  footerMobileLeft,
  selectActiveGame,
  selectOrientation,
  tournamentActions,
} from '@quanticogames/gameclient-core';
import { cashAndSit, FullGameNav, skillGames, tournamentAndSit } from 'constants/common';
import { NAV_LINKS_SKILL_GAMES, NAV_LINK_CASH_GAMES, OTHER_GAMES, SIT_AND_CASH, TOURNAMENT_AND_SIT } from 'constants/router';
import { TournamentsMenuIconSvg } from 'assets/icons/TournamentsMenuIconSvg';

import { CrazyPrize } from 'assets/icons/CrazyPrize';
import { Treble } from 'assets/icons/Treble';
import { useDispatch } from 'react-redux';

export const NAV_LINKS_DATA = [
  {
    id: 1,
    title: 'Tournaments',
    link: PATH_ROUTES.TOURNAMENTS,
    icon: <TournamentsMenuIconSvg />,
    type: 'Scheduled',
  },
  {
    id: 2,
    title: 'Sit & Go',
    link: PATH_ROUTES.SIT_AND_GO,
    icon: <SitGoMenuIconSvg />,
    type: 'Normal',
  },
  {
    id: 3,
    title: 'Colpo Grosso',
    link: PATH_ROUTES.CRAZY_PRIZE,
    icon: <CrazyPrize />,
    type: 'Crazy_Prize',
  },
  {
    id: 4,
    title: 'Filotto',
    link: PATH_ROUTES.FILOTTO,
    icon: <Treble />,
    type: 'Treble',
  },
];

export const FooterMobile: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [value, setValue] = useState(1);
  const game: Game = useSelector(selectActiveGame);
  const isFooterMenuLeft = useSelector(footerMobileLeft);
  const isMobileLandscape = useSelector(selectOrientation) === 'landscape';
  const [showLayoutFooterMenuLeft, setShowLayoutMenuLeft] = useState<boolean>(false);
  const [isFilterStatus] = useState<boolean>(
    localStorage['hideFullTable'] ? JSON.parse(localStorage['hideFullTable']) : true
  );
  const [isClickMode, setIsClickMode] = useState<boolean>(false)

  useEffect(() => {
    setIsClickMode(false)
    const updatedFilterOptions = {
      state: isFilterStatus && TournamentStateEnum.open,
      } as TournamentFilter;
    dispatch(tournamentActions.updateFilters(updatedFilterOptions));
  }, [isFilterStatus, isClickMode]);

  let navLinkDataActive;

  if (skillGames.includes(game?.code)) {
    navLinkDataActive = NAV_LINKS_SKILL_GAMES;
  } else if (FullGameNav.includes(game?.code)) {
    navLinkDataActive = NAV_LINK_CASH_GAMES;
  } else if (cashAndSit.includes(game?.code)) {
    navLinkDataActive = SIT_AND_CASH;
  } else if (tournamentAndSit.includes(game?.code)) {
    navLinkDataActive = TOURNAMENT_AND_SIT;
  }
  else {
    navLinkDataActive = OTHER_GAMES;
  }

  useEffect(() => {
    if (isFooterMenuLeft) {
      setShowLayoutMenuLeft(true);
    } else {
      setShowLayoutMenuLeft(false);
    }
  }, [isMobileLandscape, isFooterMenuLeft]);

  const onModeClick = () => {
    setIsClickMode(true)
    SoundManager.play(Sound.clickButton)
    const updatedFilterOptions = {
      code: '',
      state: isFilterStatus && TournamentStateEnum.open,
    } as TournamentFilter;
    dispatch(tournamentActions.resetFilters(updatedFilterOptions))
  }

  return (
    <BottomNavigation
      value={value}
      className={`nav-link-game-mode-mobile mobile-menu ${
        isMobileLandscape ? (showLayoutFooterMenuLeft ? 'mobile-menu-left' : 'mobile-menu-right') : ''
      }`}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
    >
      {navLinkDataActive.map((item, index) => (
        <NavLink
          onClick={onModeClick}
          key={`nav-link-${index}`}
          to={item.link}
          className={`mobile-menu-link ${navLinkDataActive.length > 3 ? '' : 'flex-1'}`}
        >
          {item.icon}
          <span>{t(item.title)}</span>
        </NavLink>
      ))}
    </BottomNavigation>
  );
};
