export const CONTAINER_ID = 'container';


export enum TOURNAMENT_TYPE {
    CRAZY_PRIZE = 'Crazy_Prize',
    SIT_AND_GO = 'Normal',
    SCHEDULED = 'Scheduled',
    TREBLE = 'Treble',
};

export const AVATAR_LIST = [
    {
        id: 1,
        src: "1.webp"
    },
    {
        id: 2,
        src: "2.webp"
    },
    {
        id: 3,
        src: "3.webp"
    },
    {
        id: 4,
        src: "4.webp"
    },
    {
        id: 5,
        src: "5.webp"
    },
    {
        id: 6,
        src: "6.webp"
    },
    {
        id: 7,
        src: "7.webp"
    },
    {
        id: 8,
        src: "8.webp"
    },
    {
        id: 9,
        src: "9.webp"
    },
    {
        id: 10,
        src: "10.webp"
    },
    {
        id: 11,
        src: "11.webp"
    },
    {
        id: 12,
        src: "12.webp"
    },
    {
        id: 13,
        src: "13.webp"
    },
    {
        id: 14,
        src: "14.webp"
    },
    {
        id: 15,
        src: "15.webp"
    },
    {
        id: 16,
        src: "16.webp"
    },
    {
        id: 17,
        src: "17.webp"
    },
    {
        id: 18,
        src: "18.webp"
    },
    {
        id: 19,
        src: "19.webp"
    },
    {
        id: 20,
        src: "20.webp"
    },
    {
        id: 21,
        src: "21.webp"
    },
    {
        id: 22,
        src: "22.webp"
    },
    {
        id: 23,
        src: "23.webp"
    },
    {
        id: 24,
        src: "24.webp"
    },
]

export const LOGO_LIST = [
    {

        // quantico
        id: 1,
        src: "logo-quantico.webp"
    },
    {
        // snai
        id: 1002,
        src: "logo-snai.jpg"
    },
    {
        // sisal
        id: 1005,
        src: "logo-sisal.webp"
    },
    {
        // sk365
        id: 1006,
        src: "logo-sks365.webp"
    },
    {
        // eplay24
        id: 1007,
        src: "logo-eplay24.webp"
    },
    {
        // betpoint
        id: 1008,
        src: "logo-betpoint.webp"
    },
    {
        // new gioco
        id: 1009,
        src: "logo-newgioco-new.webp"
    },
    {
        // betsson
        id: 1010,
        src: "logo-betsson.webp"
    },
    {
        // Scommettendo
        id: 1011,
        src: "logo-scommettendo.webp"
    },
    {
        // Fourseven
        id: 1012,
        src: "logo-foursevenplay.jpg"
    },
    {
        // Sanremo
        id: 1013,
        src: "logo-sanremo.webp"
    },
    {
        // Bgame
        id: 1014,
        src: "logo-bgame.webp"
    },
    {
        // Macaowin
        id: 1015,
        src: "logo-macaowin.webp"
    },
    {
        // CasinoMania
        id: 1016,
        src: "logo-casinomania.webp"
    },  
]


export const FullGameNav = ['ItalianPoker', 'SetteEMezzo'];
export const skillGames = ['Scopa', 'Briscola', 'Tresette', 'Burraco', 'Scala40', 'AssoPigliaTutto', 'Cirulla', 'Scopone', 'Trentuno'];

export const otherGames = [''];
export const cashAndSit = ['Telesina'];
export const tournamentAndSit = ['Bestia', 'Peppa', 'Solitario']
export const chipGames = ['ItalianPoker', 'SetteEMezzo', 'Bestia', 'Telesina']
export enum LIST_GAME {
    TRESETTE = 'Tresette',
    SOLITARIO = 'Solitario',
    SETTEEMEZZO = 'SetteEMezzo',
    SCOPONE = 'Scopone',
    SCOPA = 'Scopa',
    SCALA40 = 'Scala40',
    ITALIANPOKER = 'ItalianPoker',
    PEPPA = 'Peppa',
    CIRULLA = 'Cirulla',
    BURRACO = 'Burraco',
    BRISCOLA = 'Briscola',
    BESTIA = 'Bestia',
    ASSOPIGLIATUTTO = 'AssoPigliaTutto',
    TELESINA = 'Telesina',
    TRENTUNO = 'Trentuno',
}

export const mobileAppAgent = 'qg-mobile-app';

export const cashGame = ['Telesina', 'ItalianPoker', 'SetteEMezzo'];

export const isMobileApp = () => {
    return navigator.userAgent.includes(mobileAppAgent);
}