import React, { useCallback, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Collapse,
  IconButton,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { CommonTable } from '../../../../shared/CommonTable';
import {
  Participant,
  selectTournaments,
  Tournament,
  TournamentStateEnum,
  selectCurrentUser,
  User,
  matchActions,
  tournamentApi,
  Game,
  selectActiveGame,
  matchApi,
  TournamentMatch,
  Player,
  Match,
} from '@quanticogames/gameclient-core';
import { useSelector } from 'react-redux';
import IconLoading from 'assets/icons/Loading';
import { useDispatch } from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TableComponent from 'shared/components/tables';
import { useTranslation } from 'react-i18next';

type TableList = {
  data?: Tournament | null;
  tournamentId?: number;
  listParticipant?: TournamentMatch[];
  isChipGames: boolean;
};

const PaginationWrapper = styled(Box)(() => ({
  paddingTop: '28px',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
}));

const StyledPagination = styled(Pagination)(({ theme }) => ({
  '& .MuiPaginationItem-root': {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17px',
    color: '#0B0E11',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main,
      },
    },
  },
}));

export const TableList: React.FC<TableList> = ({ tournamentId, isChipGames }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [page, setPage] = useState<number>(1);
  const currentUser: User = useSelector(selectCurrentUser);
  const game: Game = useSelector(selectActiveGame);

  const [listParticipant, setListParticipant] = useState<any>([]);
  const [open, setOpen] = React.useState(false);
  const getAlerts = async (page) => {
    try {
      const participantResponse = await matchApi.getOnGoingTournament({tournamentId, page});
      setListParticipant(participantResponse);
    } catch (error) {
      console.error('Error fetching participants:', error);
    }
  };

  useEffect(() => {
    getAlerts(page);

    const interval = setInterval(() => {
      getAlerts(page);
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [page]);
  
  const handleOpenNewTab = (e: any, matchId : number) => {
    e.stopPropagation();
    const fetchData = async () => {
      try {
        if (matchId) {
          dispatch(
            matchActions.openMatchWindow({
              id:  matchId,
              gameCode: game.code,
            })
          );
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  };

  const HEAD_DATA_TABLE_LISTS = [
    {
      id: 'id',
      label: 'ID Table',
    },
    {
      id: 'user',
      label: 'User',
    },
  ];
  const HEAD_DATA_TABLE_LIST_CHIP = [
    {
      id: 'id',
      label: '',
    },
    {
      id: 'table id',
      label: 'ID Table',
    },
    {
      id: 'chips min',
      label: 'Chip min',
    },
    {
      id: 'chips max',
      label: 'Chip max',
    },
  ];

  const renderPlayerInfo = useCallback(
    (matches: Match) => {
      return (
        <TableCell key={matches.id}>
          <p onClick={(e) => handleOpenNewTab(e, matches?.id)}>
              {isChipGames
                ? `${t('ID Table')}: ${matches.id}`
                : matches.players.map((player: any) => player.userName).join(' - ')}
            </p>
        </TableCell>
      );
    },
    [tournamentId, i18n.language]
  );
  const renderChipInfo = useCallback(
    (matches: Match) => {
      const playerMatchPoints = matches.players.map((x:any) => x?.matchPoint);
      return (
        <>
          <TableCell key={matches.id}>
            <Box sx={{ display: 'flex' }}>
              <Typography
                sx={{
                  fontSize: '0.875rem',
                  fontWeight: 400,
                  lineHeight: '17px',
                  color: '#0B0E11',
                }}
              >
                {Math.min(...playerMatchPoints) || 0}
              </Typography>
            </Box>
          </TableCell>
          <TableCell key={matches.id}>
            <Box sx={{ display: 'flex' }}>
              <Typography
                sx={{
                  fontSize: '0.875rem',
                  fontWeight: 400,
                  lineHeight: '17px',
                  color: '#0B0E11',
                }}
              >
                {Math.max(...playerMatchPoints) || 0}
              </Typography>
            </Box>
          </TableCell>
        </>
      );
    },
    [tournamentId]
  );

  return (
    <>
      <TableComponent columns={isChipGames ? HEAD_DATA_TABLE_LIST_CHIP : HEAD_DATA_TABLE_LISTS}>
        {listParticipant?.data?.map((matches, idx) => {
          return (
            <>
              <TableRow key={`participant-table-item-${matches?.id}`}>
                {!isChipGames ? (
                  <TableCell align="center" width="50">
                    {/* {idx + 1 + 10 * (page - 1)} */}
                    {matches?.id}
                  </TableCell>
                ) : (
                  <TableCell align="right">
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                      {open ? (
                        <KeyboardArrowUpIcon fontSize="small" />
                      ) : (
                        <KeyboardArrowDownIcon fontSize="small" />
                      )}
                    </IconButton>
                  </TableCell>
                )}
                {matches ? renderPlayerInfo(matches) : <TableCell><IconLoading /></TableCell>}
                {isChipGames && renderChipInfo(matches)}
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                      <Typography variant="h6" gutterBottom component="div">
                        Detail Table
                      </Typography>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                            <TableCell width="15%">
                              No.
                            </TableCell>
                            <TableCell width="50%">
                              User Name
                            </TableCell>
                            <TableCell align="left" width="30%">
                              Chip
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {matches.players.sort((a,b) => b.matchPoint - a.matchPoint).map((players, idx) => (
                            <TableRow key={players.userName}>
                              <TableCell component="th" scope="row">
                                {idx + 1}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {players.userName}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {players?.matchPoint || 0}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </>
          );
        })}
      </TableComponent>
      {isDesktop && listParticipant?.total > 0 && (
        <PaginationWrapper>
          <StyledPagination
            onChange={(e) => setPage(page)}
            count={Math.ceil(listParticipant?.total / (listParticipant?.pageSize || 1))}
            hidePrevButton
            hideNextButton
          />
        </PaginationWrapper>
      )}
    </>
  );
};
