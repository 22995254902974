import { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material';
import theme from '../theme';
import { Router } from './routes';
import i18n from 'i18n';
import { authEvent, gameActions, GameHelper } from '@quanticogames/gameclient-core';
import RedirectLoginModal from 'shared/RedirectLoginModal/RedirectLoginModal';
import * as Sentry from "@sentry/react";
import FailBackPage from 'pages/failback';
import { useDispatch } from 'react-redux';
import { isMobileApp } from 'constants/common';


function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    const handleLanguageChange = () => {
      const newLang = localStorage.getItem('i18nextLng');
      i18n.changeLanguage(newLang);
    };
    window.addEventListener('storage', handleLanguageChange);
    localStorage.setItem('isAlreadyTour', 'false');
    return () => {
      window.removeEventListener('storage', handleLanguageChange);
    };
  }, []);

  const [isShowRedirectLoginModal, setIsShowRedirectLoginModal] = useState(false);

  useEffect(() => {
    authEvent.on("authentication-fail", () => {
      setIsShowRedirectLoginModal(true)
    });
  }, [])

  const updateOrientation = () => {
    const isLandscape = GameHelper.isLandscape() && GameHelper.isMobile();
    if (isLandscape) {
      dispatch(gameActions.updateOrientation('landscape'));
    } else {
      dispatch(gameActions.updateOrientation('portrait'));
    }
    if (isMobileApp() && window['ReactNativeWebView']) {
      window['ReactNativeWebView'].postMessage(JSON.stringify({type: 'orientation', payload: isLandscape ? 'landscape' : 'portrait'}));
    }
  }

  useEffect(() => {
    updateOrientation()
    window.addEventListener(
      'resize',
      updateOrientation
    )
    return () => {
      window.removeEventListener(
        'resize',
        updateOrientation
      )
    }
  }, [])

  return (
    <Sentry.ErrorBoundary fallback={FailBackPage}>
      <ThemeProvider theme={theme}>
        <RedirectLoginModal open={isShowRedirectLoginModal}
          onClose={() => setIsShowRedirectLoginModal(false)} />
        <Router />
      </ThemeProvider>
    </Sentry.ErrorBoundary>

  );
}

export default App;
