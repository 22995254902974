import React, { ReactNode, useEffect, useState } from 'react';
import { PaperProps, Drawer, SxProps, Theme, useTheme, useMediaQuery } from '@mui/material';
import './index.scss';
import { ArrowLeft } from 'assets/icons/ArrowLeft';
import ButtonComponent from 'shared/components/button';
import { CloseIconSvg } from 'assets/icons/CloseIconSvg';
import { FlexBox } from 'shared/Flex';
import { useSelector } from 'react-redux';
import { GameHelper, darkMode, footerMobileLeft, selectOrientation } from '@quanticogames/gameclient-core';
import classNames from 'classnames';

import { useTranslation } from 'react-i18next';

type SideDrawerProps = {
  anchor?: 'left' | 'top' | 'right' | 'bottom';
  children: ReactNode;
  open: boolean;
  onClose?: React.MouseEventHandler<HTMLElement>;
  onBack?: React.MouseEventHandler<HTMLElement>;
  isBackdropVisible?: boolean;
  MPaperProps?: PaperProps;
  sx?: SxProps<Theme>;
  showHeader?: boolean;
  titleHeader?: string;
  showButtonBack?: boolean;
  actionHeader?: React.ReactNode;
  className?: string;
};

export const SideDrawer: React.FC<SideDrawerProps> = ({
  anchor = 'right',
  children,
  open,
  onClose,
  sx,
  isBackdropVisible = true,
  MPaperProps = { sx: { width: { xs: '100%', sm: '355px' } } },
  titleHeader = '',
  showButtonBack = true,
  showHeader,
  actionHeader,
  onBack,
  className,
}): JSX.Element => {
  const isDarkMode = useSelector(darkMode);
  const theme = useTheme();
  const { t } = useTranslation('common');
  const isMobile = GameHelper.isMobile();
  const isMobileLandscape = useSelector(selectOrientation) === 'landscape';
  const isMobileScreenWidth = useMediaQuery(theme.breakpoints.down('md'));
  const isFooterMenuLeft:boolean = useSelector(footerMobileLeft);
  const [showLayoutFooterMenuLeft, setShowLayoutMenuLeft] = useState<boolean>(false);

  useEffect(() => {
    if (isFooterMenuLeft) {
      setShowLayoutMenuLeft(true);
    } else {
      setShowLayoutMenuLeft(false);
    }
  }, [isMobileLandscape, isFooterMenuLeft]);

  const drawerClass = classNames(
    'drawer-container',
    {
      'dark-mode': isDarkMode,
      'mobile-landscape': isMobileLandscape,
      'drawer-container-left': isMobileLandscape && showLayoutFooterMenuLeft,
      'drawer-container-right': isMobileLandscape && !showLayoutFooterMenuLeft
    },
    className
  );

  return (
    <Drawer
      slotProps={{ backdrop: { invisible: !isBackdropVisible } }}
      anchor={(isMobileLandscape ? showLayoutFooterMenuLeft ? 'right' : 'left' : anchor)}
      open={open}
      onClose={onClose}
      PaperProps={MPaperProps}
      className={drawerClass}
      sx={sx}
    >
      {showHeader && (
        <div className="drawer-header">
          {showButtonBack && (
            <ButtonComponent
              size="large"
              className="drawer-header-back"
              icon={<ArrowLeft />}
              variant="text"
              onClick={onBack}
            />
          )}
          {titleHeader && (
            <span
              className={`drawer-header-title ${showButtonBack || isMobile || isMobileScreenWidth ? 'center' : ''}`}
            >
              {t(titleHeader)}
            </span>
          )}
          <FlexBox className="drawer-header-action">
            {actionHeader || null}
            <ButtonComponent
              size="large"
              className="drawer-header-close"
              icon={<CloseIconSvg />}
              variant="text"
              onClick={onClose}
            />
          </FlexBox>
        </div>
      )}
      {children}
    </Drawer>
  );
};
