import React, { useEffect, useState } from 'react';
import { Button, styled, SxProps } from '@mui/material';
import { StateLabel } from '../tournament-state';
import { BADGES_COLLECTION } from '../../../../constants/badges';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import {
  CancelTournamentReply,
  Game,
  gameActions,
  matchActions,
  ParticipantStatusEnum,
  selectActiveGame,
  selectBalanceInfo,
  selectCancelReplyTournament,
  selectCurrentUser,
  selectPassFirstRound,
  Sound,
  SoundManager,
  Tournament,
  tournamentActions,
  tournamentApi,
  TournamentStateEnum,
  User,
} from '@quanticogames/gameclient-core';
import { TOURNAMENT_STATES_TITLES } from 'pages/tournament/component/tournament-label';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { convertCentToEuro, formatMoney, numberWithDecimals } from 'utils/formatCurrency';
import { CountDown } from '@quanticogames/gameclient-components';
import ButtonComponent from 'shared/components/button';
import StarIconActive from 'assets/icons/StarIconActive';
import StarIcon from 'assets/icons/StarIcon';
import LockIcon from 'assets/icons/Lock';
import ModalCancelTournament from 'pages/tournament/modal/modal-cancel-tournament';
import PassModal from 'pages/tournament/modal/modal-pass';
import '../../index.scss';
import moment from 'moment';
import { chipGames } from 'constants/common';
import { getGameType, getSpeedLabel, getWinObject } from 'utils/tournamentTitle';

type TournamentItemProps = {
  index: number;
  data: Tournament | any;
  onSelect: (id: number, index?: number) => void;
  onAction: (action: string, id: number, index?: number) => void;
  tournamentId?: number;
  className?: string;
  isFake?: boolean;
};

type CELL = {
  name: string;
  render?: (data: Tournament | any, cell: string, t?: TFunction) => string | JSX.Element;
  sx?: SxProps;
};

const StyledTableRow = styled('tr')``;

const StyledTableCell = styled('td')``;

export const TournamentItem: React.FC<TournamentItemProps> = ({
  index,
  data,
  onSelect,
  onAction,
  className,
  isFake,
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser: User = useSelector(selectCurrentUser);
  const location = useLocation();
  const path = location.pathname.split('/');
  const id = Number(path[path.length - 1]) || data?.id;
  const game: Game = useSelector(selectActiveGame);
  const balance = useSelector(selectBalanceInfo);
  const cancelTournamentData = useSelector(selectCancelReplyTournament(data?.id)) as CancelTournamentReply;
  const [isCancelTournament, setIsCancelTournament] = useState<boolean>(false);
  const tournamentPassRound = useSelector(selectPassFirstRound);
  const [showPassModal, setShowPassModal] = useState<boolean>(false);
  const [contentDialog, setContentDialog] = useState<string>('');

  const [favoriteList, setFavoriteList] = useState<number[]>(() => {
    const storedFavorites = localStorage.getItem('favoriteList');
    return storedFavorites ? JSON.parse(storedFavorites) : [];
  });

  useEffect(() => {
    const storedFavorites = localStorage.getItem('favoriteList');
    if (storedFavorites) {
      setFavoriteList(JSON.parse(storedFavorites));
    }
  }, []);

  const handleActionFavourite = (id: number, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    const isFavorited = favoriteList.includes(id);
    if (isFavorited) {
      const updatedFavorites = favoriteList.filter((itemId) => itemId !== id);
      setFavoriteList(updatedFavorites);
      localStorage.setItem('favoriteList', JSON.stringify(updatedFavorites));
    } else {
      const updatedFavorites = [...favoriteList, id];
      setFavoriteList(updatedFavorites);
      localStorage.setItem('favoriteList', JSON.stringify(updatedFavorites));
    }
  };

  const renderFavoriteIcon = (id: number) => {
    const isFavorited = favoriteList.includes(id);
    return isFavorited ? <StarIconActive /> : <StarIcon />;
  };

  useEffect(() => {
    if (cancelTournamentData) {
      setIsCancelTournament(true);
    }
  }, [cancelTournamentData]);

  const renderTitle = () => {
    const speed = data?.gameId == 9 && data?.maxPlayers == 2 ? 'Nibble' : getSpeedLabel(data?.speed);  
    const isChipGames = (gameCode: string) => chipGames.includes(gameCode);
    const winObj = isFake ? "DEMO" : getWinObject(data?.objective, data?.objectiveValue, t);

    const currentTime = moment.utc();
    const startingTime = moment.utc(data.startingTime);
    const duration = startingTime.diff(currentTime, 'seconds');

    const showSeatCount = () => {
      if (isChipGames(game?.code)) {
        return `- ${data?.maxSeats} ${t('Positions')}`;
      } else return '';
    }

    if (data?.freeroll) {
      return `${t('Freeroll')} ${game?.name} ${t(speed)} ${winObj} ${getGameType(data?.type, t) ? `- ${getGameType(data?.type, t)}` : ''} ${data.startingTime && duration > 0 ? `${numberWithDecimals(convertCentToEuro(data?.rewardValue), 2)} EURO BONUS ${showSeatCount()}` : ''}`;
    }
    if (data?.guaranteedValue) {
      return `${game?.name} ${t(speed)} ${winObj} GTD ${numberWithDecimals(convertCentToEuro(data?.guaranteedValue), 2)} ${showSeatCount()}`;
    }
    return `${game?.name} ${t(speed)} ${winObj} ${getGameType(data?.type, t) ? `- ${getGameType(data?.type, t)}` : ''} ${data.startingTime && duration > 0 ? `${showSeatCount()}`: ''}`;
  };

  const CELLS: CELL[] = [
    {
      name: 'favourite',
      render: (data, cell) => {
        return (
          <div className="tournament-favourite" onClick={(e: any) => handleActionFavourite(data.id, e)}>
            {renderFavoriteIcon(data.id)}
          </div>
        );
      },
    },
    {
      name: 'description',
      render: (data) => {
        const currentTime = moment.utc();
        const startingTime = moment.utc(data.startingTime);
        const duration = startingTime.diff(currentTime, 'seconds');
        return (
          <div className="tournament-des">
            <div className="tournament-des-content">
              <p className="tournament-des-title">{renderTitle()}</p>
              {data.startingTime && duration > 0 && (
                <p className="tournament-des-time">
                  {t('Start in')} <CountDown second={duration} format={duration > 86400 ? 'DD/MM' : duration > 3600 ? 'hh:mm:ss' : 'mm:ss'} />
                </p>
              )}
            </div>
          </div>
        );
      },
    },
    // {
    //   name: 'prize',
    //   render: (data, cell, t) => {
    //     return (
    //       <>
    //         <span>{data.cost === 0 ? t('Free') : `€ ${formatMoney(convertCentToEuro(data.cost))}`}</span>
    //       </>
    //     );
    //   },
    // },
    {
      name: 'badge',
      render: (data, cell) => {
        return (
          <div className="badge-list">
            {data.cost === 0 && <>{BADGES_COLLECTION['Free']?.icon}</>}
            {data.structure === 'MT' && data.matchType === 0 && data?.type !== 'Scheduled' ? (
              <div className="badge-icon">{`${data.maxPlayers}P`}</div>
            ) : (
              <>{BADGES_COLLECTION[data.matchType]?.icon}</>
            )}
            {data.allowToken && <>{BADGES_COLLECTION['Token_Icon']?.icon}</>}
            {BADGES_COLLECTION[data?.type]?.icon}
          </div>
        );
      },
    },
    {
      name: 'cost',
      render: (data, cell, t) => {
        return (
          <>
            <span>{data.cost === 0 ? t('Free') : `€ ${formatMoney(convertCentToEuro(data.cost))}`}</span>
          </>
        );
      },
    },
    {
      name: 'players',
      render: (data, cell, t): JSX.Element => {
        return <>{`${data.registered}/${data.maxPlayers}`}</>;
      },
    },
    {
      name: 'state',
      render: (data, cell, t): JSX.Element => (
        <StateLabel
          color={TOURNAMENT_STATES_TITLES[data[cell]]?.color}
          background={TOURNAMENT_STATES_TITLES[data[cell]]?.background}
        >
          {t(TOURNAMENT_STATES_TITLES[data[cell]]?.title)}
        </StateLabel>
      ),
    },
  ];

  const handleTournamentsDetail = () => {
    SoundManager.play(Sound.clickButton);
    dispatch(tournamentActions.tournamentsDetail(id));
    onSelect(data.id, index);
  };

  const handleOpenNewTab = (e: any, id: number) => {
    e.stopPropagation();
    const fetchData = async () => {
      try {
        const response = await tournamentApi.getDetails(id);
        const matchId = response.matches.find((m) => !!m.players.find((p) => p.id === currentUser.id));
        const spectatorMatchId = response.matches[0]?.id;
        if (matchId || spectatorMatchId) {
          dispatch(
            matchActions.openMatchWindow({
              id: matchId ? matchId?.id : spectatorMatchId,
              gameCode: game.code,
            })
          );
        }
      } catch (error) {
        console.error(error);
      }
    };

    const isDevelopment = /true/i.test(process.env.REACT_APP_DEVELOPMENT);

    if (isDevelopment) {
      onAction('play', id);
    } else {
      fetchData();
    }
  };

  const participantsLength = data?.participants?.length;
  const maxPlayers = data?.maxPlayers;
  const registeredNumber = data?.registered;
  let nonClickable =
    data?.state === TournamentStateEnum.scheduled ||
    data?.state === TournamentStateEnum.closed ||
    data?.state === TournamentStateEnum.terminated;
  nonClickable = nonClickable || data?.currentPlayerStatus == ParticipantStatusEnum.Eliminated
    || data?.participants?.some(item => item.userId == currentUser.id && item.status == ParticipantStatusEnum.Eliminated);

  const isSubscribed = data?.isSubscribed;
  const canPlay = data?.state === TournamentStateEnum.inprogress && isSubscribed;
  const canSubscribe = !isSubscribed && data.state != TournamentStateEnum.inprogress;
  const canUnsubscribe =
    isSubscribed && data.state != TournamentStateEnum.closed && data.state != TournamentStateEnum.inprogress;

  const handleCancelTournament = () => {
    dispatch(gameActions.goLobby({}));
    setIsCancelTournament(false);
  };

  useEffect(() => {
    if (data.type !== 'Scheduled' || !tournamentPassRound) return;
    
    setContentDialog('Sei passato al turno successivo, aspetta il tuo avversario');
    setShowPassModal(true);

    let timeout = setTimeout(() => {
      // dispatch(tournamentActions.resetAdvanceToNextRound({}));
      closePassModal();
    }, 10000);

    return () => {
      clearTimeout(timeout);
    };
  }, [tournamentPassRound]);

  const closePassModal = () => {
    setShowPassModal(false);
    dispatch(tournamentActions.resetAdvanceToNextRound({}));
  }

  const handlePlayScheduled = (id: number) => {
    const fetchData = async () => {
      try {
        const response = await tournamentApi.getPlayerStatus(id);
        if (response) {
          if (response?.status === 2) {
            setContentDialog('Sei stato eliminato da questo torneo');
            setShowPassModal(true);
            return;
          }
          if (response?.status === 1 && response?.ongoingMatches?.length === 0) {
            setContentDialog('Sei passato al turno successivo, aspetta il tuo avversario');
            setShowPassModal(true);
            return;
          }
        }
        onAction('play', id);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  };

  return (
    <>
      <PassModal isOpen={showPassModal} onClose={() => closePassModal()} content={contentDialog} />
      <StyledTableRow onClick={handleTournamentsDetail} className={`type-${data.type} ${className}`}>
        {CELLS.map((cell) => (
          <StyledTableCell key={cell.name} sx={cell.sx}>
            {cell.render ? cell.render(data, cell.name, t) : data[cell.name]}
          </StyledTableCell>
        ))}
        <StyledTableCell style={{ width: '138px' }}>
          {canPlay && (
            <div className="flex items-center ">
              <ButtonComponent
                variant="outlined"
                title={t('Play')}
                disabled={nonClickable}
                rouned="large"
                size="large"
                color="primary"
                fullWidth
                className="lobby-button"
                onClick={(e) => {
                  if (data.type === 'Scheduled') {
                    e.stopPropagation();
                    handlePlayScheduled(data.id);
                  } else {
                    handleOpenNewTab(e, data.id);
                  }
                }}
                startIcon={data?.hasPassword && <span style={{ position: 'absolute', left: '16px', bottom: '11px' }}><LockIcon /></span>}
              />
            </div>
          )}
          {canSubscribe && (
            <div className="subscribe-button">
              <ButtonComponent
                variant="contained"
                title={t('Subscribe')}
                rouned="large"
                size="large"
                color="success"
                fullWidth
                className="lobby-button"
                disabled={
                  nonClickable ||
                  balance === undefined ||
                  participantsLength == maxPlayers ||
                  maxPlayers === registeredNumber
                }
                onClick={(e) => {
                  e.stopPropagation();
                  onAction('subscribe', data);
                }}
                startIcon={data?.hasPassword && <span style={{ position: 'absolute', left: '14px', bottom: '12px' }}><LockIcon fill='white' /></span>}
              />
            </div>
          )}

          {canUnsubscribe && (
            <ButtonComponent
              variant="outlined"
              title={t('Unsubscribe')}
              className="lobby-button"
              disabled={nonClickable}
              color="error"
              rouned="large"
              size="large"
              fullWidth
              onClick={(e) => {
                e.stopPropagation();
                onAction('unsubscribe', data.id);
              }}
            />
          )}

          {!isSubscribed && data.state == TournamentStateEnum.inprogress && (
            <ButtonComponent
              variant="outlined"
              title={t('Spectator')}
              className="lobby-button"
              disabled={nonClickable}
              color="error"
              rouned="large"
              size="large"
              fullWidth
              onClick={(e) => handleOpenNewTab(e, data.id)}
            />
          )}
        </StyledTableCell>
      </StyledTableRow>

      {cancelTournamentData && data?.matches.length === 0 && (
        <ModalCancelTournament
          isOpen={isCancelTournament}
          onCloseModal={handleCancelTournament}
          dataCancel={t('Message cancel in game', { tournamentId: cancelTournamentData?.tournamentId })}
        />
      )}
    </>
  );
};
