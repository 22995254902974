import { PATH_ROUTES } from 'constants/routes';
import Briscola, { BriscolaTutorial } from 'games/briscola';
import Burraco, { BurracoTutorial } from 'games/burraco';
import { ItalianPoker, ItalianPokerCash, ItalianPokerTutorial } from 'games/italianPoker';
import Scala40, { Scala40Tutorial } from 'games/scala40';
import Scopa, { ScopaTutorial } from 'games/scopa';
import SetteEMezzo, { SetteEMezzoTutorial } from 'games/setteEMezzo';
import Scopone, { ScoponeTutorial } from 'games/scopone';
import LayoutGame from 'layouts/layout-game';
import { Navigate, useRoutes } from 'react-router-dom';
import { TournamentPage } from 'pages/tournament';
import Tresette, { TresetteTutorial } from 'games/tresette';
import AssoPigliaTutto, { AssoPigliaTuttoTutorial } from 'games/assoPigliaTutto';
import Cirulla, { CirullaTutorial } from 'games/cirulla';
import RegulationPage from 'pages/RegulationPage';
import InformationLobby from 'shared/Regulation/pages/InformationLobby';
import InformationGame from 'shared/Regulation/pages/InfomationGame';
import InformationScheduled from 'shared/Regulation/pages/InfomationScheduled';
import { Premio } from 'games/premio';
import { TOURNAMENT_TYPE } from 'constants/common';
import Bestia, {BestiaTutorial} from 'games/bestia';
import Solitario, { SolitarioTutorial } from 'games/solitario';
import ErrorPage from 'pages/ErrorPage';
import TelesinaCash from 'games/cashTables/telesina';
import Telesina, {TelesinaTutorial} from 'games/telesina';
import { CashTablePage } from 'pages/cash-table';
import MainLayout from 'layouts/main-layout';
import SetteEMezzoCash from 'games/cashTables/setteEMezzo';
import Peppa, { PeppaTutorial } from 'games/peppa';
import InformationClassifiche from 'shared/Regulation/pages/InfomationClassifiche';
import LayoutTutorial from 'layouts/layout-tutorial';
import Trentuno from 'games/trentuno';

export const Router = () =>
  useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          path: '/',
          element: <Navigate to="/sit-and-go" replace />,
        },
        {
          path: '/tournaments',
          element: <TournamentPage type={TOURNAMENT_TYPE.SCHEDULED} />,
        },
        {
          path: PATH_ROUTES.CASH_TABLE,
          element: <CashTablePage />,
        },
        {
          path: 'sit-and-go',
          element: <TournamentPage type={TOURNAMENT_TYPE.SIT_AND_GO} types={[TOURNAMENT_TYPE.SIT_AND_GO, TOURNAMENT_TYPE.CRAZY_PRIZE, TOURNAMENT_TYPE.TREBLE]} />,
        },
        {
          path: 'premio',
          element: <Premio />,
        },
        {
          path: 'colpo-grosso',
          element: <TournamentPage type={TOURNAMENT_TYPE.CRAZY_PRIZE} />,
        },
        {
          path: 'filotto',
          element: <TournamentPage type={TOURNAMENT_TYPE.TREBLE} />,
        },
        {
          path: PATH_ROUTES.GAME,
          element: <LayoutGame />,
          children: [
            {
              path: 'ItalianPoker',
              element: <ItalianPoker />,
            },
            {
              path: 'Telesina',
              element: <Telesina />,
            },
            {
              path: 'Scopa',
              element: <Scopa />,
            },
            {
              path: 'Burraco',
              element: <Burraco />,
            },
            {
              path: 'Scala40',
              element: <Scala40 />,
            },
            {
              path: 'Briscola',
              element: <Briscola />,
            },
            {
              path: 'Tresette',
              element: <Tresette />,
            },
            {
              path: 'SetteEMezzo',
              element: <SetteEMezzo />,
            },
            {
              path: 'AssoPigliaTutto',
              element: <AssoPigliaTutto />,
            },
            {
              path: 'Cirulla',
              element: <Cirulla />,
            },
            {
              path: 'Bestia',
              element: <Bestia />,
            },
            {
              path: 'Scopone',
              element: <Scopone />,
            },
            {
              path: 'Solitario',
              element: <Solitario />,
            },
            {
              path: 'Peppa',
              element: <Peppa />,
            },
            {
              path: 'Trentuno',
              element: <Trentuno />,
            }
          ],
        },
        {
          path: PATH_ROUTES.TABLE,
          element: <LayoutGame />,
          children: [
            {
              path: 'ItalianPoker',
              element: <ItalianPokerCash />,
            },
            {
              path: 'SetteEMezzo',
              element: <SetteEMezzoCash />,
            },
            {
              path: 'Telesina',
              element: <TelesinaCash />,
            },
          ]
        },
      ],
    },
    {
      path: 'tutorial',
      element: <LayoutTutorial />,
      children: [
        {
          path: 'Scopa',
          element: <ScopaTutorial />,
        },
        {
          path: 'Scala40',
          element: <Scala40Tutorial />,
        },
        {
          path: 'Cirulla',
          element: <CirullaTutorial />,
        }, 
        {
          path: 'AssoPigliaTutto',
          element: <AssoPigliaTuttoTutorial />,
        },
        {
          path: 'Burraco',
          element: <BurracoTutorial />,
        },
        {
          path: 'Solitario',
          element: <SolitarioTutorial />,
        },
        {
          path: 'Bestia',
          element: <BestiaTutorial />,
        },
        {
          path: 'Briscola',
          element: <BriscolaTutorial />,
        },
        {
          path: 'Scopone',
          element: <ScoponeTutorial />,
        },
        {
          path: 'ItalianPoker',
          element: <ItalianPokerTutorial />,
        },
        {
          path: 'SetteEMezzo',
          element: <SetteEMezzoTutorial />,
        },
        {
          path: 'Solitario',
          element: <SolitarioTutorial />,
        },
        {
          path: 'Bestia',
          element: <BestiaTutorial />,
        },
        {
          path: 'Tresette',
          element: <TresetteTutorial />,
        },
        {
          path: 'Peppa',
          element: <PeppaTutorial />,
        },
        {
          path: 'Telesina',
          element: <TelesinaTutorial />,
        },
      ],
    },
    {
      path: PATH_ROUTES.REGULATION,
      element: <RegulationPage />,
      children: [
        {
          path: 'lobby',
          element: <InformationLobby />,
        },
        {
          path: 'game',
          element: <InformationGame />,
        },
        {
          path: 'classifiche',
          element: <InformationClassifiche />,
        },
        {
          path: 'scheduled',
          element: <InformationScheduled />,
        },
      ],
    },
    { path: 'error-page', element: <ErrorPage /> },
    { path: '*', element: <ErrorPage /> },
  ]);
