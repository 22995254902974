import React, { useEffect, useMemo, useState } from 'react';
import { TournamentItem } from './tournament-item';
import useDrawerToggle from '../../../../hooks/useDrawerToggle';
import { DRAWER_POSITIONING } from '../../../../types/rightSideDrawer';
import { SideDrawer } from 'shared/components/side-drawer';
import { useDispatch, useSelector } from 'react-redux';
import {
  Tournament,
  TournamentFilter,
  selectFilterOptions,
  selectTournaments,
  tournamentActions,
  selectCurrentUser,
  SoundManager,
  Sound,
  User,
  selectTournamentLoading,
  GameHelper,
  TournamentStateEnum,
  selectActiveGame,
  Game,
} from '@quanticogames/gameclient-core';
import { TOURNAMENT_HEAD_DATA } from 'pages/tournament/component/tournament-label';
import Loading from 'shared/components/loading';
import { SortingOrder } from 'types/commonTable';
import ModalConfirmSubscribe from 'pages/tournament/modal/modal-confirm-subscribe';
import { useTranslation } from 'react-i18next';
import TableComponent from 'shared/components/tables';
import TableNoData from 'shared/components/tables/component/table-nodata';
import ModalComponent from 'shared/components/modal';
import { DescriptionFilters } from 'shared/Filters/DescriptionFilters';
import TournamentItemMobile from 'pages/tournament/component/list/tournament-item-mobile';
import { useMediaQuery, useTheme } from '@mui/material';
import { TOURNAMENT_FILTERS, TOURNAMENT_ITALIAN_POKER_FILTERS, TOURNAMENT_STATUS_FILTER } from 'shared/Filters/FilterConstants';
import TournamentDetail from 'pages/tournament/component/detail/tournament-info-container';
import { TOURNAMENT_TYPE, chipGames } from 'constants/common';
import { getSpeedLabel, getWinObject, getGameType } from 'utils/tournamentTitle';
import moment from 'moment';
import { convertCentToEuro, numberWithDecimals } from 'utils/formatCurrency';
import ColpoGrosso from 'pages/tournament/component/colpo-grosso';
import { getHighlightClass } from 'utils/tournamentTable';


type TournamentTableProps = {
  type?: string;
  types?: string[];
};

export const TournamentTable: React.FC<TournamentTableProps> = ({ type }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser: User = useSelector(selectCurrentUser);
  const tournamentLoading = useSelector(selectTournamentLoading);
  const filterOptions: any = useSelector(selectFilterOptions) as TournamentFilter;
  const tournaments = useSelector(selectTournaments) as Tournament[];
  const game: Game = useSelector(selectActiveGame);
  const [sortDirection, setSortDirection] = useState<SortingOrder.ASC | SortingOrder.DESC>(SortingOrder.ASC);
  const [sortColumn, setColumn] = useState<string>();
  const [tokenCode, setGetTokenCode] = useState<string>('');
  const [showFilterDescription, setShowFilterDescription] = useState<boolean>(false);
  const [showFilterStatus, setShowFilterStatus] = useState<boolean>(false);
  const [filterList, setFilterList] = useState<any>({});
  const [initialFilterList, setInitialFilterList] = useState({});
  const [oldGameMode, setOldGameMode] = useState<string>(null)
  const theme = useTheme();
  const isMobileScreenWidth = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = GameHelper.isMobile();
  const activeGame: Game = useSelector(selectActiveGame);
  const [oldGameCode, setOldGameCode] = useState<string>(null);

  const renderTitle = (tournament: Tournament) => {
    const speed = getSpeedLabel(tournament?.speed);
    const winObj = getWinObject(tournament?.objective, tournament?.objectiveValue, t);
  
    const isChipGames = (gameCode: string) => chipGames.includes(gameCode);
    const currentTime = moment.utc();
    const startingTime = moment.utc(tournament.startingTime);
    const duration = startingTime.diff(currentTime, 'seconds');

    const showSeatCount = () => {
      if (isChipGames(game?.code)) {
        return `- ${tournament?.maxSeats} ${t('Positions')}`;
      } else return '';
    }

    if (tournament?.freeroll) {
      return `${t('Freeroll')} ${game?.name} ${t(speed)} ${winObj} ${getGameType(tournament?.type, t) ? `- ${getGameType(tournament?.type, t)}` : ''} ${tournament.startingTime && duration > 0 ? `${numberWithDecimals(convertCentToEuro(tournament?.rewardValue), 2)} EURO BONUS ${showSeatCount()}` : ''}`;
    }
    if (tournament?.guaranteedValue) {
      return `${game?.name} ${t(speed)} ${winObj} GTD ${numberWithDecimals(convertCentToEuro(tournament?.guaranteedValue), 2)} ${showSeatCount()}`;
    }
    return `${game?.name} ${t(speed)} ${winObj} ${getGameType(tournament?.type, t) ? `- ${getGameType(tournament?.type, t)}` : ''} ${tournament.startingTime && duration > 0 ? `${showSeatCount()}`: ''}`;
  };

  let listTournaments = [];
  const isUserInTournament = (tournament, id) => tournament.participants?.some((p) => p.userId === id);

  const isUserSubscribe = (tournament, id) =>
    isUserInTournament(tournament, id) &&
    tournament?.state !== TournamentStateEnum.closed &&
    tournament?.state !== TournamentStateEnum.inprogress;

  if (tournaments.length > 0 && currentUser !== null && currentUser !== undefined) {
    listTournaments = tournaments.filter((tournament) => {
      let filter = true;

      filter =
        tournament?.state !== TournamentStateEnum.canceled &&
        tournament?.state !== TournamentStateEnum.end &&
        tournament?.state !== TournamentStateEnum.terminated;

      if (filter && game?.id !== tournament.gameId) {
        filter = false;
      }

      if (filter) {
        filter = filter && Boolean(tournament?.maxPlayers);
      }
      if (filter && filterOptions?.costRanges && filterOptions.costRanges.length > 0) {
        const inRange = !!filterOptions.costRanges.find(
          (range) => tournament.cost <= range.costTo && tournament.cost >= range.costFrom
        );
        filter = inRange;
      }
      if (filter && filterOptions?.costRanges && filterOptions.costRanges.length > 0) {
        const inRange = !!filterOptions.costRanges.find(
          (range) => tournament.cost <= range.costTo && tournament.cost >= range.costFrom
        );
        filter = inRange;
      }
      if (filter && filterOptions?.objectives?.length > 0 && filterOptions?.objectiveValues?.length > 0) {
        filter = filterOptions.objectives.includes(tournament.objective);
      }
      if (filter && filterOptions?.status?.length > 0) {
        filter = filterOptions.status.includes(tournament.state)
      }
      if (filter && filterOptions?.matchTypes?.length > 0) {
        filter = filterOptions.matchTypes.includes(tournament.matchType);
      }
      if (filter && filterOptions?.timeSpeeds?.length > 0) {
        filter = filterOptions.timeSpeeds.includes(tournament.speed);
      }
      if (filter && filterOptions?.code && tournament?.code) {
        const tournamentConvert = {
          ...tournament,
          titleConvert: renderTitle(tournament),
        };
        filter = tournament.code.toLowerCase().includes(filterOptions.code.toLowerCase().trim())
          || tournamentConvert.titleConvert.toLowerCase().includes(filterOptions.code.toLowerCase().trim());
      }
      if (filter && filterOptions?.state && tournament?.state) {
        filter =
          filterOptions.state == tournament?.state ||
          tournament?.isSubscribed ||
          tournament?.state === TournamentStateEnum.closed;
      }
      if (filterOptions?.maxPlayers?.length > 0 && tournament?.maxPlayers && filter) {
        filter = filterOptions.maxPlayers.indexOf(tournament.maxPlayers) > -1;
      }
      if (filter && type !== 'Normal') {
        filter = type == tournament.type;
      }
      return filter;
    });
   
    listTournaments.sort((a, b) => {
      const isUserInTournamentA = isUserInTournament(a, currentUser.id);
      const isUserInTournamentB = isUserInTournament(b, currentUser.id);

      if (isUserInTournamentA && !isUserInTournamentB) return -1;
      if (!isUserInTournamentA && isUserInTournamentB) return 1;
    });

    listTournaments.sort((a, b) => {
      const isUserSubscribeA = isUserSubscribe(a, currentUser.id);
      const isUserSubscribeB = isUserSubscribe(b, currentUser.id);

      if (isUserSubscribeA && !isUserSubscribeB) return -1;
      if (!isUserSubscribeA && isUserSubscribeB) return 1;
    });

    
  }

  const [tournamentId, setTournamentId] = useState<number>(-1);
  const { drawerAnchor, toggleDrawer } = useDrawerToggle();
  const [infoTournamentSubcribe, setInfoTournamentSubcribe] = useState({});
  const [isOpenModalConfirmSubscribe, setIsOpenModalConfirmSubscribe] = useState<boolean>(false);

  const confirmToken = (tokenCode) => {
    setGetTokenCode(tokenCode);
  };

  const onSelect = (id: number) => {
    toggleDrawer(DRAWER_POSITIONING.RIGHT, true);
    setTournamentId(id);
  };

  const handleDrawerClose = () => {
    toggleDrawer(DRAWER_POSITIONING.RIGHT, false);
  };

  const onAction = (action: string, id: number) => {

    switch (action) {
      case 'subscribe':
        return subscribe(id);
      case 'unsubscribe':
        return unsubscribe(id);
      case 'play':
        return play(id);
      default:
        return;
    }
  };

  const subscribe = (data) => {
    SoundManager.play(Sound.clickSubscribe);
    setInfoTournamentSubcribe(data);
    const tournamentId = data?.id;
    if (data?.cost > 0 || (data?.cost === 0 && data?.hasPassword === true)) {
      setTimeout(() => {
        setIsOpenModalConfirmSubscribe(true);
      }, 100);
    } else {
      dispatch(tournamentActions.subscribeTournament({ tournamentId, tokenCode: tokenCode || null }));
    }
  };

  const play = async (id: number) => {
    SoundManager.play(Sound.clickButton);
    localStorage.setItem('lobbyUrl', window.location.pathname);
    dispatch(tournamentActions.play(id));
  };

  const unsubscribe = (tournamentId: number) => {
    SoundManager.play(Sound.clickUnsubscribe);
    dispatch(tournamentActions.unsubscribeTournament({ tournamentId }));
  };

  const subscribeGoUp = (list: any) => list.forEach((item, i) => {
    if (item.isSubscribed) {
      list.splice(i, 1);
      list.unshift(item);
    }
  });

  const sortedListTournaments = useMemo(() => {
    const newTournaments = [...listTournaments];
    const subscribedTournaments = newTournaments.filter(tournament => tournament.isSubscribed);
    subscribedTournaments.sort((a,b) => {
      if (a.highlight === b.highlight) {
        return b.id - a.id;
      }
      return a.highlight - b.highlight;
    })

    const hightLightTournaments = newTournaments.filter(tournament => !tournament.isSubscribed && tournament.highlight !== 0);
    const otherTournaments = newTournaments.filter(tournament => !tournament.isSubscribed && tournament.highlight === 0);
    
    
    if (sortColumn && sortDirection) {
      const dir = sortDirection === SortingOrder.ASC ? 1 : -1;
      if (sortColumn === "players") {
        otherTournaments.sort((a, b) => {
          if(a.registered === 0 && b.registered === 0) {
            return b.id - a.id;
          }
          if(a.registered === 0 && b.registered !== 0) {
            return 1;
          }
          if(a.registered !== 0 && b.registered === 0) {
            return -1;
          }
          const ratioA = a.registered / a.maxPlayers;
          const ratioB = b.registered / b.maxPlayers;

          if (ratioA === ratioB) {
            if (a.registered === b.registered) {
              return (a.id > b.id ? 1 : -1) * dir;
            }
          }
          return (ratioA > ratioB ? 1 : -1) * dir;
        });
      } else {
        otherTournaments.sort((a, b) => (a[sortColumn] > b[sortColumn] ? 1 : -1) * dir);
      }
    } else {
      otherTournaments.sort((a, b) => {
        if(a.registered === 0 && b.registered === 0) {
          return b.id - a.id;
        }
        if(a.registered === 0 && b.registered !== 0) {
          return 1;
        }
        if(a.registered !== 0 && b.registered === 0) {
          return -1;
        }
        const ratioA = a.registered / a.maxPlayers;
        const ratioB = b.registered / b.maxPlayers;
       
        if (ratioA === ratioB) {
          if (a.registered === b.registered) {
            return b.id - a.id;
          }
          return b.registered - a.registered;
        }
        return ratioB - ratioA;
      })
    }
    if(hightLightTournaments.length > 0) {
      hightLightTournaments.sort((a, b) => {
        if (a.highlight === b.highlight) {
          return b.id - a.id;
        }
        return b.highlight - a.highlight;
      });
    }
    const combinedTournaments = [...subscribedTournaments,...hightLightTournaments, ...otherTournaments];
    subscribeGoUp(combinedTournaments);
    return combinedTournaments;
  }, [listTournaments, sortColumn, sortDirection, tournamentLoading]);

  const handleSortTable = (column, direction) => {
    setColumn(column);
    setSortDirection(direction);
  };

  const handleFilter = (column) => {
    if (column === 'description') {
      setShowFilterDescription(true);
    } else if (column === 'state') {
      setShowFilterStatus(true)
    }
  };

  const handleApply = () => {
    dispatch(tournamentActions.updateFilters(filterList));
    onClose();
  }

  const onClose = () => {
    if (showFilterDescription) {
      setShowFilterDescription(false)
    } else {
      setShowFilterStatus(false)
    }
  }

  useEffect(() => {
    setInitialFilterList(filterList);
  }, [showFilterDescription, showFilterStatus]);

  useEffect(() => {
    if (window.location.href === oldGameMode) return;
    setOldGameMode(window.location.href)
    setFilterList({})
  }, [window.location.href]);

  const handleCancelFilter = () => {
    setFilterList(initialFilterList);
    onClose();
  }

  const activeGameId = JSON.parse(localStorage.getItem('activeGame'))?.id;
  const tournamentFilters = !showFilterDescription ? TOURNAMENT_STATUS_FILTER : activeGameId === 3 ? TOURNAMENT_ITALIAN_POKER_FILTERS : TOURNAMENT_FILTERS;
  const updatedFilterOptions: any = { ...filterList };

  const filterData = (name: string, values: any[]) => {
    const options = tournamentFilters[name].options.filter(o => values.includes(o.id));

    switch (name) {
      case 'cost':
        updatedFilterOptions.costLabels = options.map(o => o.label);
        updatedFilterOptions.costRanges = options.map(o => o.costRange);
        break;

      case 'objective':
        updatedFilterOptions.objectiveLabels = options.map(o => o.label);
        updatedFilterOptions.objectives = options.map(o => o.objective);
        updatedFilterOptions.objectiveValues = options.map(o => o.objectiveValue);
        break;

      case 'matchType':
        updatedFilterOptions.matchTypeLabels = options.map(o => o.label);
        updatedFilterOptions.matchTypes = options.map(o => o.matchType);
        break;

      case 'timeSpeed':
        updatedFilterOptions.timeSpeedLabels = options.map(o => o.label);
        updatedFilterOptions.timeSpeeds = options.map(o => o.timeSpeed);
        break;

      case 'maxPlayers':
        updatedFilterOptions.maxPlayersLabels = options.map(o => o.label);
        updatedFilterOptions.maxPlayers = options.map(o => o.maxPlayers);
        break;

      case 'status':
        updatedFilterOptions.statusLabels = options.map(o => o.label);
        updatedFilterOptions.status = options.map(o => o.status);
        break;
    }

    setFilterList(updatedFilterOptions);
  };

  useEffect(() => {
    if (activeGame?.code === oldGameCode) return;
    setOldGameCode(activeGame?.code)
    setFilterList({})
  }, [activeGame]);

  return (
    <>
      {tournamentLoading ? (
        <Loading className="!top-[200px] !md:top-[192px] loading-tournament" />
      ) : (<>
        {(type === TOURNAMENT_TYPE.TREBLE || type === TOURNAMENT_TYPE.CRAZY_PRIZE) ? (
           <ColpoGrosso type={type} subscribe={subscribe} onSelect={onSelect} />
        ) : (
          <>
            {(isMobileScreenWidth || isMobile) ? (
              <div className='tournament-mobile-list'>
                {sortedListTournaments.length > 0 ? sortedListTournaments.map((item, index) => (
                  <TournamentItemMobile
                    key={`tournament-mobile-${item.id}`}
                    index={index}
                    data={item}
                    onSelect={onSelect}
                    onAction={onAction}
                    className={getHighlightClass(item.highlight)}
                  />)) : <TableNoData />
                }
              </div>
            ) : (
              <TableComponent
                columns={TOURNAMENT_HEAD_DATA}
                onClickSort={handleSortTable}
                onFilterModal={handleFilter}
                className='tournament-table-container'
              >
                {sortedListTournaments.length > 0 ? sortedListTournaments.map((item, index) => (
                  <TournamentItem
                    key={`tournament-${item.id}`}
                    index={index}
                    data={item}
                    onSelect={onSelect}
                    onAction={onAction}
                    className={getHighlightClass(item.highlight)}
                  />
                )) : <tr>
                  <td colSpan={10} style={{ border: 'none' }}>
                    <TableNoData />
                  </td>
                </tr>}
              </TableComponent>
            )}
          </>
        )}

        <ModalComponent
          open={showFilterDescription ? showFilterDescription : showFilterStatus}
          className={showFilterDescription ? 'tourmanent-filter-modal' : 'status-filter-modal'}
          title={showFilterDescription ? t('Description filter') : t('Status filter')}
          onClose={handleCancelFilter}
          typeModal='filter'
          onCancel={handleCancelFilter}
          onOk={handleApply}
          textOk={t('Apply')}
          textCancel={t('Cancel')}
          showFooter={true}
          size='small'
        >
          <DescriptionFilters filterList={filterList} filterData={filterData} tournamentFilters={tournamentFilters} />
        </ModalComponent>
      </>
      )}
      <SideDrawer
        anchor="right"
        open={drawerAnchor.right}
        onClose={handleDrawerClose}
        showHeader={true}
        titleHeader={t('Tournament information')}
        showButtonBack={false}
        className="tournament-info-drawer"
      >
        <TournamentDetail onClose={handleDrawerClose} onAction={onAction} id={Number(tournamentId)} />
      </SideDrawer>

      {isOpenModalConfirmSubscribe && (
        <ModalConfirmSubscribe
          isOpen={isOpenModalConfirmSubscribe}
          data={infoTournamentSubcribe}
          onCloseModal={() => setIsOpenModalConfirmSubscribe(false)}
          confirmToken={confirmToken}
        />
      )}
    </>
  );
};
