import { all } from 'redux-saga/effects'
import { authSaga, chatSaga, gameSaga, matchSaga, tournamentSaga, cashTableSaga } from '@quanticogames/gameclient-core';
import { italianPokerSaga, italianPokerCashSaga } from '../games/italianPoker';

import { scopaSaga } from '../games/scopa';
import { burracoSaga } from '../games/burraco';
import { scala40Saga } from '../games/scala40';
import { briscolaSaga } from '../games/briscola';
import { tresetteSaga } from 'games/tresette';
import { setteEMezzoSaga } from 'games/setteEMezzo';
import { assoPigliaTuttoSaga } from 'games/assoPigliaTutto';
import { cirullaSaga } from 'games/cirulla';
import { bestiaSaga } from 'games/bestia';
import { scoponeSaga } from 'games/scopone';
import { solitarioSaga } from 'games/solitario';
import { telesinaCashSaga } from 'games/cashTables/telesina';
import { telesinaSaga } from 'games/telesina';
import { setteEMezzoCashSaga } from 'games/cashTables/setteEMezzo';
import { peppaSaga } from 'games/peppa';
import { trentunoSaga } from 'games/trentuno';

export default function* rootSaga() {
  yield all([
    authSaga(),
    chatSaga(),
    gameSaga(),
    tournamentSaga(),
    matchSaga(),
    italianPokerSaga(),
    scopaSaga(),
    burracoSaga(),
    scala40Saga(),
    briscolaSaga(),
    tresetteSaga(),
    setteEMezzoSaga(),
    assoPigliaTuttoSaga(),
    cirullaSaga(),
    bestiaSaga(),
    scoponeSaga(),
    solitarioSaga(),
    cashTableSaga(),
    italianPokerCashSaga(),
    telesinaSaga(),
    telesinaCashSaga(),
    setteEMezzoCashSaga(),
    peppaSaga(),
    trentunoSaga()
  ])
}
