import React from 'react';
import { styled, Switch, SwitchProps } from '@mui/material';

interface ToggleProps extends SwitchProps {
  variant?: 'light' | 'dark';
  backgroundicon?: string;
  backgroundtoggledown?: string;
  checked: boolean;
}

const StyledSwitch = styled(Switch, {
  shouldForwardProp: (prop) => prop !== 'variant',
})<ToggleProps>(({ variant, backgroundicon, backgroundtoggledown }) => ({
  ...(variant === 'light' && {
    height: '24px',
    width: '44px',
    padding: 0,
    alignItems: 'center',
    '& .MuiSwitch-switchBase': {
      height: '20px',
      padding: '3px',
      marginTop: '2px',
      '& .MuiSwitch-thumb': {
        background: '#fff',
        width: '20px',
        height: '20px',
      },
      '& + .MuiSwitch-track': {
        background: '#8E8E8E',
        height: '24px',
        opacity: 1,
        borderRadius: '24px',
      },
      '&.Mui-checked': {
        transform: 'translateX(18px)',
        '& .MuiSwitch-thumb': {
          background: '#fff',
        },
        '& + .MuiSwitch-track': {
          background: '#0066FF',
        },
      },
    },
  }),
  ...(variant === 'dark' && {
    height: '42px',
    width: '54px',
    alignItems: 'center',
    '& .MuiSwitch-switchBase': {
      height: '42px',
      padding: '12px',
      '& .MuiSwitch-thumb': {
        background: backgroundicon ? backgroundicon : '#144B46',
        border: '1px solid rgba(255, 255, 255, 0.06)',
        width: '18px',
        height: '18px',
      },
      '& + .MuiSwitch-track': {
        background: '#000',//backgroundtoggledown,
        height: '16px',
        borderRadius: '8px',
      },
      '&.Mui-checked': {
        color: '#24DEDB',
        transform: 'translateX(12px)',
        '& .MuiSwitch-thumb': {
          background: backgroundicon,
        },
        '& + .MuiSwitch-track': {
          background: backgroundtoggledown,
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          background: '#24DEDB',
          opacity: 0.5,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          background:
            'linear-gradient(180deg, rgba(14, 183, 180, 0.15) 0%, rgba(14, 183, 180, 0.4) 100%)',
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#24DEDB',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        background: '#144B46',
        border: '1px solid rgba(255, 255, 255, 0.06)',
        opacity: 0.5,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        background:
          'linear-gradient(180deg, rgba(14, 183, 180, 0.3) 0%, rgba(14, 183, 180, 0.25) 100%)',
        opacity: 0.3,
      },
    },
  }),
}));

export const Toggle: React.FC<ToggleProps> = ({
  variant = 'light',
  backgroundicon = '#144B46',
  backgroundtoggledown = '#06F',
  ...props
}) => <StyledSwitch variant={variant} backgroundicon={'#fff'} backgroundtoggledown={"#06F"} {...props} />;
