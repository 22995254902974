import { chatActions, Game, selectActiveGame, selectActiveMatchId, selectChatAnimations, selectChatTableEnabled } from "@quanticogames/gameclient-core";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listEmojiData } from "shared/Chat/Component/list-icon-emoji";
import { particleAnimation } from "shared/Chat/Component/particule-animation";

export const ChatAnimation = () => {
    const dispatch = useDispatch();
    const animationEvents: any[] = useSelector(selectChatAnimations);
    const roomId = useSelector(selectActiveMatchId);
    const chatTableEnabled = useSelector(selectChatTableEnabled);
    const activeGame: Game = useSelector(selectActiveGame)
    useEffect(() => {
        const event = animationEvents && animationEvents.length && animationEvents[animationEvents.length - 1];
        if (!event) return;

        switch (event.eventName) {
            case 'Emoji':
                handleEmoji(event.data);
                break;
            case 'Message':
                handleMsg(event.data);
                break;
        }

        dispatch(chatActions.removeAnimationEvent({ conversationId: roomId }));
    }, [animationEvents]);

    const handleEmoji = ({ title, playerId }) => {
        if (!chatTableEnabled) return;
        const imgElement = document.createElement('img');
        imgElement.classList.add('img-emoji');
        imgElement.src = listEmojiData[title]?.src;
         // Solitario
         const isReverse = activeGame?.id === 14
        particleAnimation(imgElement, playerId, true, isReverse)?.play();
    };

    const handleMsg = ({ message, playerId }) => {
        if (!chatTableEnabled) return;
        const msgEl = document.createElement('div');
        msgEl.classList.add('player-msg');
        msgEl.textContent = message;
        // Solitario
        const isReverse = activeGame?.id === 14
        particleAnimation(msgEl, playerId, false, isReverse)?.play();
    };

    return (
        <></>
    );
};

