import {
  Dialog,
  DialogActions,
  DialogContent,
  
  DialogTitle,
  IconButton,
  SxProps,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { GameHelper, darkMode, selectOrientation } from '@quanticogames/gameclient-core';
import { CloseIconSvg } from 'assets/icons/CloseIconSvg';
import { useEffect, useRef, useState } from 'react';
import './index.scss';
import ButtonComponent, { ColorButton } from 'shared/components/button';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';

type ModalComponenProps = {
  open: boolean;
  onClose?: () => void;
  onOk?: () => void;
  onCancel?: () => void;
  title?: React.ReactNode;
  children: React.ReactNode;
  textCancel?: string;
  textOk?: string;
  showFooter?: boolean;
  showIconClose?: boolean;
  size?: 'medium' | 'large' | 'small';
  sx?: SxProps<Theme>;
  typeModal?: 'notification' | 'confirm' | 'filter' | 'chat';
  sizeButton?: 'medium' | 'large' | 'small';
  className?: string;
  typeColorOk?: ColorButton;
  disableTextOK?: boolean;
};

const ModalComponent = (props: ModalComponenProps) => {
  const {
    open,
    onClose,
    className,
    title,
    children,
    onOk,
    onCancel,
    textCancel,
    textOk,
    showFooter,
    size = 'medium',
    sx,
    showIconClose = true,
    typeModal = 'confirm',
    sizeButton = 'medium',
    typeColorOk,
    disableTextOK = false
  } = props;
  const isMobile = GameHelper.isMobile();
  const [scroll] = useState('paper');
  const descriptionElementRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobileScreenWidth = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileLandscape = useSelector(selectOrientation) === 'landscape';
  const isDarkMode = useSelector(darkMode);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Dialog
      fullScreen={isMobile}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      scroll="paper"
      className={`modal-component modal-${size} modal-${typeModal} ${className || ''} ${isMobileLandscape ? 'mobile-landscape' : ''
        } ${isDarkMode ? 'modal-dark-mode' : ''}`}
      sx={sx}
    >
      <DialogTitle
        className={`modal-title ${!showIconClose || isMobile || isMobileScreenWidth ? 'text-center justify-center' : ''
          }`}
      >
        {t(`${title}`)}
      </DialogTitle>
      {showIconClose && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="modal-close"
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            color: '#fff',
          }}
        >
          <CloseIconSvg />
        </IconButton>
      )}
      <DialogContent dividers={scroll === 'paper'} className="modal-main">
        <div
          className="modal-content"
          ref={descriptionElementRef}
        >
          {children}
        </div>
      </DialogContent>
      {showFooter && (
        <DialogActions className="modal-footer">
          {textCancel && (
            <ButtonComponent
              variant={typeModal === 'confirm' ? 'contained' : 'outlined'}
              onClick={onCancel}
              size={sizeButton}
              title={textCancel}
              className="modal-button"
              color={typeModal === 'confirm' ? 'error' : 'inherit'}
            />
          )}

          {textOk && (
            <ButtonComponent
              variant="contained"
              onClick={onOk}
              size={sizeButton}
              className="modal-button"
              title={textOk}
              color={typeColorOk || 'primary'}
              disabled={disableTextOK}
            />
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};
export default ModalComponent;
