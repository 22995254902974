export const { describeScopaMove } =
    process.env.REACT_APP_NAMESPACE_SCOPA ? require(process.env.REACT_APP_NAMESPACE_SCOPA)
        : require('@quanticogames/gameclient-scopa');

export const { describeBuracoMove } =
    process.env.REACT_APP_NAMESPACE_BURRACO ? require(process.env.REACT_APP_NAMESPACE_BURRACO)
        : require('@quanticogames/gameclient-burraco');

export const { describeScala40Move } =
    process.env.REACT_APP_NAMESPACE_SCALA40 ? require(process.env.REACT_APP_NAMESPACE_SCALA40)
        : require('@quanticogames/gameclient-scala40');

export const { Scala40Replay } =
    process.env.REACT_APP_NAMESPACE_SCALA40 ? require(process.env.REACT_APP_NAMESPACE_SCALA40)
        : require('@quanticogames/gameclient-scala40');

export const { BurracoReplay } =
    process.env.REACT_APP_NAMESPACE_BURRACO ? require(process.env.REACT_APP_NAMESPACE_BURRACO)
        : require('@quanticogames/gameclient-burraco');

export const { describeBriscolaMove } =
    process.env.REACT_APP_NAMESPACE_BRISCOLA ? require(process.env.REACT_APP_NAMESPACE_BRISCOLA)
        : require('@quanticogames/gameclient-briscola');

export const { describeAssoPigliaTuttoMove } =
    process.env.REACT_APP_NAMESPACE_ASSOPIGLIATUTTO ? require(process.env.REACT_APP_NAMESPACE_ASSOPIGLIATUTTO)
        : require('@quanticogames/gameclient-asso-pigliatutto');

export const { describeBestiaReplay } =
    process.env.REACT_APP_NAMESPACE_BESTIA ? require(process.env.REACT_APP_NAMESPACE_BESTIA)
        : require('@quanticogames/gameclient-bestia');

export const { describeTresetteMove } =
    process.env.REACT_APP_NAMESPACE_TRESETTE ? require(process.env.REACT_APP_NAMESPACE_TRESETTE)
        : require('@quanticogames/gameclient-tresette');

export const { describeItPokerMove, describePokerCashMove } =
    process.env.REACT_APP_NAMESPACE_ITALIANPOKER ? require(process.env.REACT_APP_NAMESPACE_ITALIANPOKER)
        : require('@quanticogames/gameclient-italianpoker');

export const { describeBestiaMove } =
    process.env.REACT_APP_NAMESPACE_BESTIA ? require(process.env.REACT_APP_NAMESPACE_BESTIA)
        : require('@quanticogames/gameclient-bestia');

export const { describeCirullaMove } =
    process.env.REACT_APP_NAMESPACE_CIRULLA ? require(process.env.REACT_APP_NAMESPACE_CIRULLA)
        : require('@quanticogames/gameclient-cirulla');

export const { describeTelesinaMove, describeTelesinaCashMove } =
    process.env.REACT_APP_NAMESPACE_TELESINA_CASH ? require(process.env.REACT_APP_NAMESPACE_TELESINA_CASH)
    : require('@quanticogames/gameclient-telesina');

export const { describeScoponeMove } =
    process.env.REACT_APP_NAMESPACE_SCOPONE ? require(process.env.REACT_APP_NAMESPACE_SCOPONE)
        : require('@quanticogames/gameclient-scopone');

export const { describeSetteEMezzoMove } =
    process.env.REACT_APP_NAMESPACE_SETTE_E_MEZZO ? require(process.env.REACT_APP_NAMESPACE_SETTE_E_MEZZO)
        : require('@quanticogames/gameclient-setteemezzo');

export const { describeSolitarioMove } =
    process.env.REACT_APP_NAMESPACE_SOLITARIO ? require(process.env.REACT_APP_NAMESPACE_SOLITARIO)
        : require('@quanticogames/gameclient-solitario');
        
export const { describePeppaMove } =
    process.env.REACT_APP_NAMESPACE_PEPPA ? require(process.env.REACT_APP_NAMESPACE_PEPPA)
        : require('@quanticogames/gameclient-peppa');

export const { describeTrentunoMove } =
    process.env.REACT_APP_NAMESPACE_TRENTUNO ? require(process.env.REACT_APP_NAMESPACE_TRENTUNO)
        : require('@quanticogames/gameclient-trentuno');
