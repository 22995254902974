import React, { useState } from 'react';
import { MenuItem, Select, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { configActions, darkMode, footerMobileLeft, selectConfigState, selectOrientation } from '@quanticogames/gameclient-core';
import { useDispatch, useSelector } from 'react-redux';
import { Toggle } from 'shared/components/toggle';
import { SoundIcon } from 'assets/icons/SoundIcon';
import { DarkModeIcon } from 'assets/icons/DarkModeIcon';
import { ChatMenuIconSvg } from 'assets/icons/ChatMenuIconSvg';
import PointerIcon from 'assets/icons/PointerIcon';

import ManualIconSvg from 'assets/icons/ManualIconSvg';
import i18n from 'i18n';

const Title = styled(Typography)(() => ({
  color: '#101223',
  fontWeight: 500,
  fontSize: '14px',
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  borderBottom: '1px solid #D2D2D3',
  height: 40,
  padding: '8px 12px',
  '.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
    opacity: 1,
  },
  '.text': {
    flex: 1,
  },
}));

const listLang = [
  {label: 'English', value: 'en'},
  {label: 'Italian', value: 'it'}
]

export const Settings: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const configState = useSelector(selectConfigState);
  const isFooterMenuLeft:boolean = useSelector(footerMobileLeft);
  const isDarkMode: boolean = useSelector(darkMode) || false;
  const isMobileLandscape = useSelector(selectOrientation) === 'landscape';
  const [lang, setLang] = useState<string>(localStorage.getItem('i18nextLng') || 'it');

  const [showTut, setShowTut] = useState(localStorage['showTour'] === 'true' ? false : true);

  const soundToggle = (e) => dispatch(configActions.toggleSound(e.target.checked));
  const chatToggle = (e) => dispatch(configActions.toggleChat(e.target.checked));
  const darkModeToggle = (e) => dispatch(configActions.toggleDarkMode(e.target.checked));
  const footerMobileToggle = (e) => dispatch(configActions.toggleFooterMobileLeft(e.target.checked));

  const handleChangeShowTut = (e) => {
    const checked = e.target.checked;
    setShowTut(checked);
    localStorage.setItem('showTour', checked ? 'false' : 'true');
  }

  const handleChangeLanguage = (e) => {
    const { value } = e.target
    setLang(value)
    if (value) i18n.changeLanguage(value);
  }

  return (
    <>
      <Title className="setting-item">
        <SoundIcon />
        <span className="text">{t('Sound')}</span>
        <Toggle checked={configState?.soundEffect} onChange={soundToggle} size={'small'} />
      </Title>

      <Title className="setting-item">
        <ChatMenuIconSvg />
        <span className="text">{t('Chat')}</span>
        <Toggle checked={configState?.chatEnabled} onChange={chatToggle} size={'medium'} />
      </Title>

      <Title className="setting-item">
        <DarkModeIcon />
        <span className="text">{t('Dark mode')}</span>
        <Toggle checked={isDarkMode} onChange={darkModeToggle} />
      </Title>

      <Title className='setting-item'>
        <ChatMenuIconSvg />
        <span className='text'>{t("Change Language")}</span>
        <Select
          value={lang}
          onChange={handleChangeLanguage}
          aria-label="Game details tabs"
          variant="outlined"
          className='select-component'
        >
          {listLang.map((item, index) => (
            <MenuItem key={index} value={item?.value}>
              {item?.label}
            </MenuItem>
          ))}
        </Select>
      </Title>

      {isMobileLandscape && (
        <Title className="setting-item">
          <PointerIcon />
          <span className="text">{t('Modalità per mancini')}</span>
          <Toggle checked={isFooterMenuLeft} onChange={footerMobileToggle} />
        </Title>
      )}
      
      <Title className="setting-item">
        <ManualIconSvg />
        <span className="text">{t('Tutorial')}</span>
        <Toggle checked={showTut} onChange={handleChangeShowTut} />
      </Title>
    </>
  );
};
