import React, { useState } from 'react';
import ProfileHeader from './components/profile-header';
import './index.scss';
import Menu from 'layouts/profiles/components/profile-menu';
import { TokenBlackIcon } from 'assets/icons/TokenBlack';
import { SideDrawer } from 'shared/components/side-drawer';
import { Rankings } from 'shared/Rankings';
import TokenModal from 'pages/tournament/modal/modal-token';
import { useSelector } from 'react-redux';
import { GameHelper, SessionInfo, User, selectCurrentUser, selectOrientation, selectSessionInfo } from '@quanticogames/gameclient-core';
import { ProfileIcon } from 'assets/icons/ProfileIcon';
import { ProfileHistory } from 'assets/icons/ProfileHistory';
import { ProfileTournament } from 'assets/icons/ProfileTournament';
import { ProfileCashTable } from 'assets/icons/ProfileCashTable';

import { ProfileChat } from 'assets/icons/ProfileChat';
import { ProfileSetting } from 'assets/icons/ProfileSetting';
import ProfileUser from 'layouts/profiles/components/profile';
import { Settings } from 'layouts/profiles/components/setting';
import { History } from 'layouts/profiles/components/history';
import { Token } from 'layouts/profiles/components/token';
import { HISTORY_TYPE } from 'utils/constants';
import FooterMenu from 'layouts/footer/component/footer-menu';
import { FlexBox } from 'shared/Flex';
import { Dialog, DialogTitle, IconButton, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { HelpOutline } from "@mui/icons-material";
import { LogOutIconSvg } from 'assets/icons/LogOutIconSvg';
import ButtonComponent from 'shared/components/button';
import { Chat } from 'layouts/profiles/components/chat';
import { CloseIconSvg } from 'assets/icons/CloseIconSvg';

import { AppCrack } from 'layouts/profiles/appCrack';

type ProfileProps = {
  onClose?: () => void;
  rankingUser: any;
};

let options = [
  {
    label: 'Profile',
    id: 'profile-menu-settings',
    icon: <ProfileIcon />,
    value: 'Profile',
  },
  {
    label: 'History',
    id: 'profile-menu-history',
    icon: <ProfileHistory />,
    children: [
      {
        label: 'Tournaments',
        icon: <ProfileTournament />,
        value: 'History > Tournament',
      },
      {
        label: 'Cash Table',
        icon: <ProfileCashTable />,
        value: 'History > Cash table',
      },
    ],
  },
  {
    label: 'Token',
    id: 'profile-menu-token',
    icon: <TokenBlackIcon />,
    value: 'Token',
  },
  {
    label: 'Chat Alerts',
    icon: <ProfileChat />,
    value: 'Chat Alerts',
  },
  // {
  //   label: 'Warning',
  //   id: 'profile-menu-warning',
  //   icon: <ProfileWarning />,
  //   children: [
  //     {
  //       label: 'Cheat List',
  //       icon: <ProfileCheat />,
  //       value: 'Warning > Cheat',
  //     },
  //     {
  //       label: 'Chat',
  //       icon: <ProfileChat />,
  //       value: 'Avvertimenti',
  //     },
  //   ],
  // },
  {
    label: 'Settings',
    icon: <ProfileSetting />,
    value: 'Settings',
  },
  {
    label: 'Exit',
    icon: <LogOutIconSvg />,
    value: 'Exit',
  },
  {
    label: 'AppCrack',
    icon: <HelpOutline />,
    value: 'AppCrack',
  }
];

const Profile: React.FC<ProfileProps> = ({ onClose, rankingUser }) => {
  const { t } = useTranslation()
  const [content, setContent] = useState<string | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const onCloseDr = () => {
    setIsDrawerOpen(false);
    setContent(null);
  };
  const [isOpenModalConfirmSubscribe, setIsOpenModalConfirmSubscribe] = useState<boolean>(false);
  const currentUser: User = useSelector(selectCurrentUser);
  const isMobile = GameHelper.isMobile();
  const isMobileLandscape = useSelector(selectOrientation) === 'landscape';
  const theme = useTheme();
  const isMobileScreenWidth = useMediaQuery(theme.breakpoints.down('md'));
  const [openConfirmModelQuit, setOpenConfirmModelQuit] = useState(false);
  const sessionInfo = useSelector(selectSessionInfo) as SessionInfo;

  const handleClickMenu = (value: string) => {

    if (
      value === 'rankings' ||
      // value === 'warnings' ||
      value === 'Settings' ||
      value === 'Profile' ||
      value === 'Token' ||
      value === 'Warning > Cheat' ||
      value === 'Chat Alerts' ||
      value === 'History > Tournament' ||
      value === 'History > Cash table' ||
      value === 'AppCrack'
    ) {
      setContent(value);
      setIsDrawerOpen(true);
    }
    if (value === 'token') {
      setIsOpenModalConfirmSubscribe(true);
    }
    if (value === 'Exit') {
      setOpenConfirmModelQuit(true);
    }
  };

  const onConfirmQuit = () => {
    localStorage.removeItem("token")
    const casinoUrl = sessionInfo?.backUrl || "";
    window.location.href = casinoUrl;
  }
  return (
    <FlexBox
      flexDirection="column"
      justifyContent="flex-start"
      height="100%"
      width="100%"
      className="profile-container"
      id="profile-container"
    >
      <ProfileHeader rankingUser={rankingUser} />
      <div className="profile-menu-box">
        <Menu options={options} onClickMenu={handleClickMenu} />
      </div>
     
      {((isMobileLandscape && isMobile) || isMobileScreenWidth) && (
        <div className="footer-container-mobile">
          <FooterMenu />
        </div>
      )}
      <SideDrawer
        open={isDrawerOpen}
        onClose={onCloseDr}
        anchor="right"
        titleHeader={t(content)}
        showHeader={true}
        onBack={onCloseDr}
      >
        {content === 'Profile' && <ProfileUser rankingUser={rankingUser}/>}
        {content === 'Token' && <Token />}
        {content === 'Rankings' && <Rankings onClose={onCloseDr} />}
        {content === 'History > Tournament' && <History type={HISTORY_TYPE.TOURNAMENT} />}
        {content === 'History > Cash table' && <History type={HISTORY_TYPE.CASH_TABLE} />}
        {/* {content === 'Warning > Cheat' && <Cheat />} */}
        {content === 'Chat Alerts' && <Chat />}
        {content === 'Settings' && <Settings />}
        {content === 'AppCrack' && <AppCrack />}
      </SideDrawer>
      {isOpenModalConfirmSubscribe && (
        <TokenModal
          isOpen={isOpenModalConfirmSubscribe}
          onCloseModal={() => setIsOpenModalConfirmSubscribe(false)}
          data={{ userId: currentUser?.id }}
        />
      )}
     <Dialog
        open={openConfirmModelQuit}
        onClose={() => setOpenConfirmModelQuit(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={`modal-component modal-small modal-subsccribe ${isMobileLandscape ? 'mobile-landscape' : ''
        }`}
        scroll="paper"
        sx={{ '& .MuiDialog-paper': { width: '300px', height: '110px' } }}
      >
        <DialogTitle 
          id="alert-dialog-title"
          className={`modal-title text-center justify-center`}
          sx={{ marginBottom: '20px' }}
        >
          {t('Confirm exit?')}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpenConfirmModelQuit(false)}
          className="modal-close"
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            color: '#fff',
          }}
        >
          <CloseIconSvg />
        </IconButton>
        <Stack gap={'10px'} direction={'row'} alignItems="center" width="100%" justifyContent={'center'}>
          <ButtonComponent
            color='error'
            title='Annulla'
            variant='contained'
            size='large'
            sx={{ minWidth: '120px !important' }}
            onClick={() => setOpenConfirmModelQuit(false)}
          />
          <ButtonComponent
            title='Esci'
            variant='contained'
            size='large'
            sx={{ minWidth: '120px !important' }}
            onClick={() => onConfirmQuit()}
          />
        </Stack>
      </Dialog>
    </FlexBox>
  );
};

export default Profile;
