import { SKIN } from 'constants/skin';
import { useSelector } from 'react-redux';
import './index.scss';
import { Container, useMediaQuery, useTheme } from '@mui/material';
import {
  selectCasino,
  selectActiveGame,
  Game,
  SoundManager,
  Sound,
  configActions,
  gameActions,
  selectConfigState,
  GameHelper,
  countPlayerOnline,
} from '@quanticogames/gameclient-core';

import { InfoOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import SoundVolume from 'assets/icons/SoundVolumn';
import { ChangeGame } from 'layouts/headers/component/change-game';
import HeaderBalance from 'layouts/headers/component/header-balance';
import HeaderUser from 'layouts/headers/component/header-user';
import SoundVolumnMute from 'assets/icons/SoundVolumnMute';
import { PATH_ROUTES } from 'constants/routes';
import ButtonComponent from 'shared/components/button';
import { FlexBox } from 'shared/Flex';
import { useLocation } from 'react-router-dom';
import { getCurrentTime } from 'utils/caculateTime';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { t } = useTranslation("common");
  const skinType: string = useSelector(selectCasino) || 'quantico';
  const casinoTitleEl = document.getElementById('casino-title');
  const faviconEl = document.getElementById('favicon') as HTMLLinkElement;
  const dispatch = useDispatch();
  const game: Game = useSelector(selectActiveGame);
  const configState = useSelector(selectConfigState);
  faviconEl.href = `/favicon-${SKIN[skinType].logoHeader}.ico`;
  casinoTitleEl.innerHTML = `${SKIN[skinType].title}`;
  const isMobile = GameHelper.isMobile();
  const locationRoute = useLocation();

  const countOnline = useSelector(countPlayerOnline) as number;
 
  const [time, setTime] = useState<any>();
  setInterval(() => {
    setTime(getCurrentTime());
  }, 1000);
  
  const theme = useTheme();
  const isMobileScreenWidth = useMediaQuery(theme.breakpoints.down('sm'));
  const isGamePage =
    location.pathname.includes(PATH_ROUTES.GAME) ||
    location.pathname.includes('information/regulation') ||
    location.pathname.includes('/tables/');

  const handleCheckSound = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    dispatch(configActions.toggleSound(!configState?.soundEffect));
  };

  useEffect(() => {
    const gameId = game?.id
    if (!gameId) return;
    if (locationRoute.pathname === "/cash-tables" || locationRoute.pathname.includes("/tables")) {
      dispatch(gameActions.subscribeCashGame({gameId: gameId}))
    }
  }, [locationRoute, game?.id]);


  

  return (
    <div className="header-top-container">
      <Container className="h-full !flex items-center justify-between">
        <div className="header-top-main">
          {isGamePage ? (
            <FlexBox className="max-h-[40px] max-w-[200px]" justifyContent={'center'}>
              <img src={`/images/logo/skin/logo-${SKIN[skinType].logoHeader}.webp`} className="h-full" />
            </FlexBox>
          ) : (
            <ChangeGame />
          )}
          <div className="header-top-right">
            {!isMobile && countOnline > 0 && (
              <div className='header-balance-text'>{t('online now', { countOnline })} | {time}</div>
            )}
            {!isMobileScreenWidth && (
              <ButtonComponent
                onClick={() => SoundManager.play(Sound.clickButton)}
                icon={<InfoOutlined color="inherit" />}
                href={`/information/regulation/game?tabs=${game?.code}`}
                target={isMobile ? "" : "_blank"}
                variant="text"
                size="large"
                className="header-regulation-icon"
                sx={{ padding: 0, minWidth: '0 !important' }}
              />
            )}
            <a onClick={handleCheckSound} className="sound-volumn">
              {configState?.soundEffect ? <SoundVolume /> : <SoundVolumnMute />}
            </a>
            <HeaderBalance />
            <HeaderUser />
          </div>
        </div>
        </Container>
    </div>
  );
};

export default Header;
