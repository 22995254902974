import { CashTable, selectCashTableLoading, selectOrientation } from '@quanticogames/gameclient-core';
import { TableInfo } from './cash-info';
import { PlayerTab } from './cash-player-tab';
import { FlexBox } from 'shared/Flex';
import { useSelector } from 'react-redux';
import IconLoading from 'assets/icons/Loading';

type CashGameDetailProps = {
  onClose?: () => void;
  data?: CashTable,
};

export const CashGameDetail = ({ onClose }: CashGameDetailProps) => {
  const isMobileLandscape = useSelector(selectOrientation) === 'landscape';
  const loading: boolean = useSelector(selectCashTableLoading);
  return (
    <FlexBox className="tournament-info-container" flexDirection={isMobileLandscape ? "row" : "column"} gap={isMobileLandscape ? 0 : 15}>
      {loading ? (
        <FlexBox
          className="w-full h-full absolute"
          sx={{ top: 0, left: 0, zIndex: 9999, background: "#00000036", alignItems: "center" }}
        >
          <IconLoading color={'#fff'} />
        </FlexBox>
      ) : (
        <>
          <TableInfo onClose={onClose} />
          <PlayerTab />
        </>
      )}
    </FlexBox>
  );
};
