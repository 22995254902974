import { ChatBubble } from '@mui/icons-material';
import { Popover, useMediaQuery, useTheme } from '@mui/material';
import { CashTablePlayer, Game, GameHelper, GameRound, User, chatActions, isInittedChat, matchActions, selectActiveGame, selectActiveMatchId, selectActiveTableId, selectCashTableCurrentPlayer, selectCurrentUser, selectIsOpenDrawerChat, selectOrientation, selectRound } from '@quanticogames/gameclient-core';
import { ChatZoomInIcon } from 'assets/icons/ChatZoomInIcon';
import { CloseIconSvg } from 'assets/icons/CloseIconSvg';

import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { CloseButton, FAButton } from 'shared/ButtonsCollection';
import { Chat } from 'shared/Chat';
import { CHAT_TABS } from 'shared/Chat/type';
import { FlexBox } from 'shared/Flex';
import ButtonComponent from 'shared/components/button';
import { SideDrawer } from 'shared/components/side-drawer';

const ChatDesktop = () => {
  const { t } = useTranslation();
  const activeGame: Game = useSelector(selectActiveGame);
  const isOpenDrawerChat: boolean = useSelector(selectIsOpenDrawerChat);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobilePortrait = GameHelper.isMobile() && GameHelper.isPortrait();
  const isMobileLandscape = useSelector(selectOrientation) === 'landscape';
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'chat-popover' : undefined;
  const dispatch = useDispatch();
  const currentUser: User = useSelector(selectCurrentUser);
  const matchId: number = useSelector(selectActiveMatchId);
  const tableId: number = useSelector(selectActiveTableId);
  const currentCashTablePlayer: CashTablePlayer = useSelector(selectCashTableCurrentPlayer(tableId));
  const round: GameRound = useSelector(selectRound());
  const inittedChat = useSelector(isInittedChat);

  const rooms = useMemo(() => {
    const roomMatch = matchId && ("t" + matchId) || tableId && currentCashTablePlayer && ("c" + tableId) || null;
    const finishedRound = round?.state === 'finished';
    return [
      ...(roomMatch && !finishedRound ? [
        { label: CHAT_TABS[2].label, roomId: roomMatch, value: CHAT_TABS[2].value },
      ] : []),
      { label: CHAT_TABS[0].label, roomId: 'lobby', value: CHAT_TABS[0].value },
      { label: CHAT_TABS[1].label, roomId: 'helpdesk' + currentUser?.id, value: CHAT_TABS[1].value },
    ];
  }, [matchId, tableId, currentCashTablePlayer, round?.state]);

  const [value, setValue] = useState<any>(rooms[0]);

  useEffect(() => {
    setValue(rooms[0])
  }, [rooms]);

  const onClose = () => {
    setAnchorEl(null);
  };

  const handleFAButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isMobile || isMobileLandscape) {
      dispatch(chatActions.updateOpenDrawer(!isOpenDrawerChat));
      dispatch(matchActions.updateSwitcher(false));
    } else {
      setAnchorEl(open ? null : event.currentTarget);
    }
  };

  const handleChatZoomIn = () => {
    dispatch(chatActions.updateOpenDrawer(true));
    setAnchorEl(null);
  };

  const ChatZoomIn = () => (
    <ButtonComponent icon={<ChatZoomInIcon />} variant="text" onClick={handleChatZoomIn} className="!min-w-0" />
  );

  const handleClick = (e) => {
    setValue(e);
  }

  useEffect(() => {
    if (inittedChat && value) dispatch(chatActions.activeConversation(value.roomId));
  }, [inittedChat, value]);

  return (
    <>
      <FAButton
        aria-describedby={id}
        className={`chat-button chat-${activeGame?.code} ${open || isOpenDrawerChat ? 'active' : ''}`}
        icon={open || isOpenDrawerChat ? <CloseIconSvg /> : <ChatBubble />}
        sx={{
          position: 'fixed',
          right: '16px',
        }}
        onClick={handleFAButtonClick}
      />

      <Popover
        anchorEl={anchorEl}
        open={open}
        id={id}
        className={`chat-desktop chat-desktop-${activeGame?.code} ${isMobilePortrait ? 'portrait' : isMobileLandscape ? 'landscape' : ''
          }`}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={onClose}
        disableAutoFocus
        disableEnforceFocus
      >
        <div className="popover-header">
          {t('Chat')}
          <FlexBox className="popover-header-action" alignItems={'center'}>
            <ChatZoomIn />
            <CloseButton onClick={onClose} />
          </FlexBox>
        </div>
        <Chat activeSlectRoom={value} setActiveSlectRooms={handleClick} rooms={rooms} handleClose={onClose} />
      </Popover>

      <SideDrawer
        open={isOpenDrawerChat}
        onClose={() => dispatch(chatActions.updateOpenDrawer(false))}
        anchor="right"
        titleHeader="Chat"
        showHeader={true}
        showButtonBack={false}
        className="match-switcher-drawer"
        isBackdropVisible={false}
      >
        <Chat activeSlectRoom={value} setActiveSlectRooms={handleClick} rooms={rooms} handleClose={() => dispatch(chatActions.updateOpenDrawer(false))} />
      </SideDrawer>
    </>
  );
};

export default ChatDesktop;
