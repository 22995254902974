import { Game, selectActiveGame, selectCasino } from '@quanticogames/gameclient-core';
import { isMobileApp } from 'constants/common';
import { SKIN } from 'constants/skin';
import { isIOS, isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';

type TableProps = {
    children: React.ReactNode;
};

const Table = ({ children }: TableProps) => {
    const games: Game = useSelector(selectActiveGame);
    const skinType: string = useSelector(selectCasino) || 'quantico';
    const isApp = isMobileApp();
    return (
        <div
            id="table-round"
            className={`table-${games?.code} ${games?.code} relative rounded-[999px]
            flex items-center justify-center
            ${isIOS && isMobile ? 'ios-mobile' : ''}
            ${isApp ? 'mobile-app' : ''}
            `}
        >
            <div className={`table-logo absolute transform left-[50%] -translate-x-1/2 -translate-y-1/2 top-[50%] opacity-40
             table-logo-${games?.code}`}>
                <img
                    src={`/images/logo/skin/logo-${SKIN[skinType]?.logoTable ?? SKIN[skinType]?.logoHeader}.webp`}
                    className='xl:h-[50px] h-[40px] max-w-[300px]'
                    style={{ filter: "brightness(0) invert(1)" }}
                />
            </div>
            {children}
        </div>
    );
};
export default Table;
